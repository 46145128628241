import { AuthLayout } from "./AuthLayout"
import { AuthLoading } from "./AuthLoading"
import { BeatLoader } from "./BeatLoader"
import { SignupPage } from "./SignupPage"
import { AuthPageDescription } from "./AuthPageDescription"

export const AuthComponents = {
    AuthLayout,
    AuthLoading,
    BeatLoader,
    SignupPage,
    AuthPageDescription
}