import { HStack, Stack, Text, useColorModeValue, Icon } from "@chakra-ui/react"
import { HiOutlineChat, HiOutlineMail, HiOutlinePhone } from "react-icons/hi"
import * as React from "react"

export const OrderSupport = () => {
    return (
        <Stack spacing="3">
            <Text fontSize="sm" color={useColorModeValue('gray.700', 'gray.200')}>
                Have questions? or Need help to complete your order?
            </Text>
            <HStack
                spacing="8"
                color={useColorModeValue('blue.500', 'blue.200')}
                fontWeight="semibold"
            >
                <HStack>
                    <Icon as={HiOutlineChat} boxSize="5"/>
                    <Text>Live Chat</Text>
                </HStack>
                <HStack>
                    <Icon as={HiOutlinePhone} boxSize="5"/>
                    <Text>Phone</Text>
                </HStack>
                <HStack>
                    <Icon as={HiOutlineMail} boxSize="5"/>
                    <Text>Email</Text>
                </HStack>
            </HStack>
        </Stack>
    )
}

export default OrderSupport
