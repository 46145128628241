import Users from "./Users"
import LocalizedStrings from "react-localization"


export class AccountSettings extends Users {
    constructor(props) {
        super(props)

        this.strings = new LocalizedStrings({
            en: {
                title: "Settings",
            },
            ko: {
                title: "프로필",
            }
        })

        this.title = this.strings.title
        this.showOnVersions = ["members", "subscribers"]
    }
}

export default AccountSettings
