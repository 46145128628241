import { mode } from "@chakra-ui/theme-tools"

export const AuthLoading = {
    baseStyle: props => ({
        // color: "gray.700",
        background: mode('gray.50', 'inherit')(props),
    }),
    variants: {
        admin: {
        },
        dreammore: {
            bg: "bg2.50",
            // background: "primary.200",
            // bgGradient: "linear(to-b, bg.300, bg.200)"
        },
        dreamupup: {},
        "dreamupup-bg": {}
    }

}
