import * as React from "react"
import { Flex, Switch, FormControl, FormLabel, useColorModeValue } from "@chakra-ui/react"
import { NumberInputControl } from "formik-chakra-ui"

export const SplitPaymentsForm = ({ name, strings, orderTotal }) => {
    const [show, setShow] = React.useState(false)
    // React.useEffect(() => { console.log("split", orderTotal)}, [orderTotal])

    return (
        <Flex mt={8} direction={"column"} w={"full"}>
            <FormControl as={Flex} w={"full"} justify={"space-between"} align={"center"} mb={3}>
                <FormLabel htmlFor='splitPayments'
                           fontSize="md"
                           fontWeight="semibold"
                           color={useColorModeValue('gray.700', 'gray.200')}>{ strings.splitPayments }</FormLabel>
                <Switch
                    id='splitPayments'
                    onChange={e => setShow(e.target.checked)} isChecked={show} size={"lg"}/>
            </FormControl>
            <Flex w={"full"} transition={"all 0.2s"} d={show ? "flex" : "none"}>
                <NumberInputControl
                    showStepper={false}
                    key={orderTotal}
                    name={name}
                    numberInputProps={{defaultValue: orderTotal, bg: "white"}}
                    label={strings.splitPlaymentAmt}/>
            </Flex>
        </Flex>
    )
}