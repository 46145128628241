import * as React from "react"
import { useLocation } from "react-router-dom"

export const PathsContext = React.createContext({})

export const PathsProvider = ({ children }) => {
    let location = useLocation()
    let { pathname: path } = location

    const rootPath = `/${path.split("/")[1]}`
    const paths = {
        rootPath,
        list: rootPath,
        new: `${rootPath}/new`,
        edit: id => `${rootPath}/${id}/edit`,
        view: id => `${rootPath}/${id}/view`,
        isList: path.endsWith(rootPath),
        isEdit: path.includes("edit"),
        isNew: path.includes("new"),
        isView: path.includes("view"),
        userProfile: id => `/users/${id}/edit`
    }
    return <PathsContext.Provider value={ paths }>{children}</PathsContext.Provider>
}

export const usePaths = () => React.useContext(PathsContext)

export default PathsProvider
