import { createContext, useContext } from "react"
import { useDocument } from "swr-firestore-v9"

import { useAuth } from "../auth/contexts/AuthProvider"
import { useConstants } from "./ConstantsProvider"

const UserContext = createContext({
    user: null,
    updateUser: () => {}
})

export const UserProvider = ({ children }) => {
    let { currentUser } = useAuth()
    let { currentUserCol } = useConstants()

    let { data: user, update: updateUser } = useDocument(currentUser ? `${currentUserCol}/${currentUser.uid}` : null, {
        parseDates: ["createdAt", "birthday", "updatedAt"]
    })

    return <UserContext.Provider value={{ user, updateUser }} >{ children }</UserContext.Provider>

}

export const useUser = () => useContext(UserContext)

export default UserProvider
