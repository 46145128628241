import Collection from "../Collection"
import LocalizedStrings from "react-localization"
import { BsCurrencyDollar } from "react-icons/bs"
import * as yup from "yup"
import dates from "../../utils/dates"
import validation from "../../utils/validation"
import { getConstants } from "../../config"

const config = getConstants()
const { sites } = config

export class PricingPlans extends Collection {
    constructor(props) {
        super(props)

        this.name = "plans"
        this.title = "Pricing Plans"
        this.modelLabel = "Plan"

        // this.path = "/plans"
        this.showOnVersions = ["disabled"]
        this.section = "Website" // Sidebar section on admin pane
        this.sidebarIcon = <BsCurrencyDollar/>

        this.searchKeys = []
        this.parseDates = [ "createdAt", "updatedAt" ]
        this.imageFields = []

        this._strings = {
            en: {
                title: "Pricing",
                labels: {
                    site: "Site",
                    name: "Name",
                    description: "Description",
                    price: "Price (numbers only)",
                    priceStr: "Price Label",
                    features: "Features",
                    mostPopular: "Most Popular",
                    isHidden: "Hidden",
                    orderIndex: "Order Index",

                    featureLabel: "Feature"
                }
            },
            ko: {
                title: "패키지"
            }
        }

        this.strings = new LocalizedStrings(this._strings)

        this.title = this.strings.title

        this.schema = {
            site: yup.string().oneOf([...sites.map(site => site.value)]).default(sites[0].value),
            name: yup.string()
                .required(validation.strings.required)
                .default(""),
            description: yup.string()
                .required(validation.strings.required)
                .default(""),
            price: yup.number()
                .required(validation.strings.required)
                .default(1000000),
            priceStr: yup.string()
                .required(validation.strings.required)
                .default("100만원"),
            features: yup.array()
                .default([ "" ]),
            mostPopular: yup.boolean()
                .default(false),
            isHidden: yup.boolean()
                .default(false),
            orderIndex: yup.number()
                .default(0), // or always order by price
            createdAt: yup.date()
                .transform(validation.parseDateString)
                .default(dates.date.now),
            updatedAt: yup.date()
                .transform(validation.parseDateString)
                .default(dates.date.now)
        }

        this.sortBy = [
            [ "isHidden", "orderIndex" ],
            [ "desc", "asc" ]
        ]

        this.colOpts = {
            listen: true,
            where: ["isHidden", "==", false],
            // orderBy: [ "createdAt", "desc" ],
            parseDates: this.parseDates

        }

        this.initialData = initialData
        this.swrOpts = {
            fallbackData: this.initialData
        }
    }

}

const initialData = [
    // {
    //     id: "dtVzkbwUJdMlTT95GnkA",
    //     name: "베이직 패키지 (BASIC)",
    //     description: "꿈을더하다 베이직 패키지 입니다",
    //     mostPopular: true,
    //     price: 1980000,
    //     priceStr: "198만원",
    //     features: [
    //         "꿈을더하다 기본 공구교육 패키지 입니다",
    //         "커리큘럼을 확인하시려면 담당자에게 문의해 주세요",
    //         "단톡방, 꿈친소 혜택은 포함되어 있지 않으니 유의해 주세요!"
    //     ]
    // },
    {
        id: "j2G3ddAI4ih9p7M71N2y",
        name: "프리미엄 패키지 (PREMIUM)",
        description: "꿈을더하다 프리미엄 패키지 입니다.",
        price: 2970000,
        priceStr: "297만원",
        features: [
            "꿈을더하다 공구교육 프리미엄 패키지 입니다.",
            "기본교육 완료 후  단톡방 입장, 매일 핫한 공구상품을 공유받으세요!",
            "꿈친소(소개모집) 혜택이 포함되어 있습니다."
        ]

    }
]

export default PricingPlans
