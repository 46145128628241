
export const config = {
    loginRoute: "/auth/login",
    forgotRoute: "/auth/login/forgot",
    signupRoute: "/auth/signup",

    strings: {
        en: {
            validation: {
                required: "Required",

            },
            signupPage: {
                title: "Join the Dream family!",
                isMember: "Already have an account?",
                loginLink: "Login here"
            },
            loginPage: {
                // title: "",
                // description: "",

                emailLabel: "Email Address",
                emailRequired: "Email is required",
                passwordLabel: "Password",
                passwordRequired: "Password is required",
                submitButton: "Sign in"
            },

            resetPasswordPage: {
                description: "Create a new password",
                passwordLabel: "Password",
                confirmLabel: "Confirm Password",
                tooShort: "Password must be at least {min} characters",
                passwordsDontMatch: "Passwords don't match",
                required: "Required",
                setPasswordButton: "Set Password",
                codeInvalid: "This code is no longer valid. Please try again.",
                resetErrorMessage: "There was an error reseting your password. Please try again.",
                backToLoginBtn: "Back to Login",
                somethingIsWrong: "Something is wrong. Try logging in instead."
            },
            forgotPasswordPage: {
                // title: "Password Reset",
                description: "Enter your email to receive a message to reset your password.",

                email: "Email",
                submitButton: "Send",
                successMessage: "Email sent to {email}",

                sentSuccessTitle: "Sent!",
                sentSuccessMessage: "Please check {email} for a message.",

                sentErrorTitle: "Oops!",
                sentErrorMessage: "Something went really wrong.",
                backToLoginButton: "Back to Login Page"
            }
        },
        ko: {
            validation: {
                required: "필수",
            },
            signupPage: {
                title: "Join the Dream family!",
                isMember: "이미 회원가입이 되어 있으세요?",
                loginLink: "로그인 하기"
            },
            loginPage: {
                // title: "",
                // description: "",

                emailLabel: "이메일 주소",
                emailRequired: "이메일 주소를 정확히 입력해주세요",
                passwordLabel: "비밀번호",
                passwordRequired: "비밀번호를 정확히 입력해주세요",
                submitButton: "로그인"
            },

            resetPasswordPage: {
                description: "새 비밀번호 설정",
                passwordLabel: "비밀번호",
                confirmLabel: "비밀번호 확인",
                tooShort: "Password must be at least {min} characters",
                passwordsDontMatch: "Passwords don't match",
                required: "필수",
                setPasswordButton: "비밀번호 등록",
                codeInvalid: "이 링크는 유효하지 않습니다. 다시 시도해 주세요.",
                resetErrorMessage: "에러가 발생했습니다. 다시 시도해 주세요.",
                backToLoginBtn: "로그인 하러 가기",
                somethingIsWrong: "Something is wrong. Try logging in instead."
            },
            forgotPasswordPage: {
                // title: "Password Reset",
                description: "가입하실 때 입력한 이메일 주소로 임시비밀번호를 보내드립니다",

                email: "이메일",
                submitButton: "전송",
                successMessage: "가입하신 이메일을 확인해주세요",

                sentSuccessTitle: "전송",
                sentSuccessMessage: "이메일",

                sentErrorTitle: "에러",
                sentErrorMessage: "가입정보가 없는 이메일입니다.",

                backToLoginButton: "로그인 하러 가기"
            }
        }
    }
}

config.defaultAuthRoute = config.signupRoute

export default config