import Loading from "./Loading"
import { VStack } from "@chakra-ui/react"
import * as React from "react"

export const PageLoading = (props) => (
    <VStack h={"100vh"}>
        <Loading
            h={"80vh"}
            spinnerProps={{
                // color: "var(--chakra-colors-gray-400)"
            }}
            { ...props }

            />
    </VStack>
)

export default PageLoading
