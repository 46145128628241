import * as React from "react"
import { Box } from "@chakra-ui/react"

export function TermsContainer({ children, ...rest }) {
    return <Box
        className="terms"
        fontFamily={"'Noto Sans KR'"}
        wordBreak={"keep-all"}
        maxW={"4xl"}
        px={3}
        py={2}
        mx={"auto"}
        sx={{
            "h1, h2, h3": {
                fontFamily: "Montserrat",
                textTransform: "uppercase",
                marginBottom: ".5rem",
                fontWeight: 600,
                letterSpacing: "tighter",
                lineHeight: 1.5,
                // color: "#32325d"
            },
            "h1": {
                fontSize: "1.0rem",
            },
            "h2": {
                fontSize: "0.9rem",
                fontFamily: "'Noto Sans KR'",
                fontWeight: 600,
                marginTop: "12px",
            },
            "h3": {
                fontSize: "1.0rem"
            },
            "ul": {
                fontSize: "0.9rem",
                px: 0,
                py: 3,
                marginBottom: "1rem",
                listStyle: "none"
            },
            "li": {
                mb: 2,
                fontWeight: 300,
                letterSpacing: "tight",
                lineHeight: 1.4
            },
            "li span, ul li ul li span": {
                fontWeight: 600,
                mr: 2,
                // color: "#32325d"
            },
            "ul li ul": {
                my: 2,
                px: 4
            },
            "ul li ul li span": {
                fontWeight: 400,
            },
            "p": {
                fontSize: "0.9rem",
                fontWeight: 300,
                lineHeight: 1.7,
                marginBottom: ".5rem",
                letterSpacing: "tight"
            }

        }}
        {...rest}
    >{ children }</Box>
}