import { reduce, isEmpty, chain, keyBy, groupBy } from "lodash";

export const getMenuItems = (collections) => {

    const collectionsByName = keyBy(collections, "name")
    const sectionGroups = groupBy(collections, "section")

    const collectionSections = reduce(collections, (list, col) => {
        if (!list.includes(col.section)) list.push(col.section)
        return list
    }, [])

    const getMenuFor = (version) => {
        return chain(collectionSections)
            .map((colName) => {
                return sectionGroups[colName].filter(col => {
                    return (isEmpty(col.showOnVersions) || col.showOnVersions.includes(version))
                })
            })
            .filter(col => !col.name)
            .flatten()
            .value()
    }

    // console.log("getMenuVersion", getMenuFor("members"))

    return {
        menu: {
            groups: sectionGroups,
            sections: collectionSections
        },
        colByName: collectionsByName,
        getMenuFor
    }
}

export default getMenuItems
