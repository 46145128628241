// import { mode } from "@chakra-ui/theme-tools"

export const AuthPageDescription = {
    baseStyle: props => ({
        color: "gray.700",
    }),
    variants: {
        dreammore: {
            // color: "white"
        },
        dreamupup: {},
        "dreamupup-bg": {}
    }

}
