import {
    Box,
    Button,
    createIcon,
    Flex,
    List,
    ListItem,
    Text,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'

import CardLoading from "../../components/loading/CardLoading"
import { useConstants } from "../../contexts/ConstantsProvider"

const CheckIcon = createIcon({
    viewBox: '0 0 17 12',
    d: 'M0 5.82857L1.64571 4.11429L5.48571 7.2L14.8114 0L16.4571 1.71429L5.48571 12L0 5.82857Z',
})

const PricingDetail = (props) => {
    const { children, ...rest } = props
    return (
        <ListItem display="flex" alignItems="flex-start" fontWeight="medium"  {...rest}>
            <CheckIcon mr="4" mt="1" color={mode('primary.500', 'primary.300')} />
            <Text as="span" display="inline-block">
                {children}
            </Text>
        </ListItem>
    )
}

const PopularBadge = (props) => (
    <Box
        whiteSpace="nowrap"
        top="-4"
        left="50%"
        transform="translateX(-50%)"
        pos="absolute"
        rounded="md"
        fontWeight="bold"
        fontSize="sm"
        px="4"
        py="1"
        textTransform="uppercase"
        bg="primary.500"
        color="white"
        {...props}
    />
)

const PriceDisplay = (props) => {
    const { currency, price } = props
    return (
        <Flex w="100%" align="center" justify="center" my="5" fontWeight="bold" >
            <Text fontSize="4xl" mr="2">
                {currency}
            </Text>
            <Text fontSize="6xl" lineHeight="1" letterSpacing="tight" sx={{
                "s::before": {
                    background: "var(--chakra-colors-primary-500)"
                }
            }}>
                {price}
            </Text>
        </Flex>
    )
}

const PricingWrapper = (props) => {
    const { highlight, ...rest } = props
    const popularStyles = {
        borderWidth: '2px',
        borderColor: 'primary.500',
        zIndex: 1,
        px: '8',
        pt: '12',
        pb: '10',
        top: {
            // lg: '-8',
        },
    }
    const styles = highlight ? popularStyles : null
    return (
        <Box
            className={"pricing-wrapper"}
            w="full"
            maxW="md"
            mx="auto"
            bg={mode('white', 'gray.700')}
            px="10"
            pt="8"
            pb="8"
            rounded="lg"
            shadow="base"
            position="relative"
            {...styles}
            {...rest}
        />
    )
}

export const PricingPlanCard = (props) => {
    const { onClick, item, ...rest } = props

    const constants = useConstants()
    const strings = constants.strings.members.pricingPlans

    if (!item) return <CardLoading size={"md"} maxW={"md"} />
    const { name, description, priceStr, mostPopular, features } = item
    // console.log("ITEM", item)

    return (
        <Box mb={4} className={"pricing-plan-card"}>
        <PricingWrapper highlight={mostPopular} {...rest}>
            {mostPopular && <PopularBadge>{ strings.mostPopularLabel }</PopularBadge>}

            <Flex direction="column" justify="center" >
                <Text align="center" fontSize="2xl" fontWeight="bold" color={"primary.500"}>
                    {name}
                </Text>
                <Text
                    align="center"
                    mt="2" color={mode('gray.600', 'gray.400')}
                    // maxW="16rem"
                    wordBreak={"keep-all"}
                    mx="auto">
                    {description}
                </Text>
                <PriceDisplay price={priceStr} />
                {/*<Box*/}
                {/*    as="button"*/}
                {/*    display="inline-flex"*/}
                {/*    mx="auto"*/}
                {/*    color={mode('brand.500', 'brand.300')}*/}
                {/*    fontWeight="semibold"*/}
                {/*>*/}
                {/*    Switch to yearly billing*/}
                {/*</Box>*/}
            </Flex>

            <List stylePosition="outside" mt="4" spacing={2}>
                {features.map((feature, idx) => (
                    <PricingDetail key={idx}>{feature}</PricingDetail>
                ))}
            </List>

            <Button
                type={"submit"}
                minH="3.5rem"
                rounded="lg"
                fontWeight="bold"
                colorScheme={mostPopular ? 'primary' : 'primary'}
                bg={mostPopular ? "primary.500" : "primary.400"}
                mt="8"
                w="100%"
                onClick={onClick}
            >
                { strings.submitButtonLabel }
            </Button>
        </PricingWrapper>
        </Box>
    )
}

export default PricingPlanCard
