import * as React from "react"
import { useOutletContext } from "react-router-dom"
import { Flex, VStack } from "@chakra-ui/react"
import { useCollectionClasses } from "../../contexts/CollectionClassesProvider"
import CardLoading from "../../components/loading/CardLoading"
import PricingPlanReceiptSummary from "../../members/checkout/PricingPlanReceiptSummary"
import { useDocument } from "swr-firestore-v9"
import { useConstants } from "../../contexts/ConstantsProvider"
import { useUser } from "../../contexts/UserProvider"
import { useCollectionClass } from "../../contexts/CollectionClassProvider"
import { SplitPaymentCheckoutForm } from "./SplitPaymentCheckoutForm"


export const TransactionResultView = () => {
    const context = useOutletContext()
    const classes = useCollectionClasses()
    const collection = useCollectionClass()
    const constants = useConstants()
    const { user } = useUser()

    const { docs: { transactions } } = context

    let { data: plan } = useDocument(
        transactions?.data ? `${classes.plans.name}/${transactions.data.plan_id}` : null)

    if (!transactions?.data || !plan) return <CardLoading />

    const balance = collection.getBalance(transactions.data)
    console.log(transactions.data)
    console.log("balance", balance)

    // console.log(transactions.data)
    // console.log(plan)

    return (
        <VStack spacing={4}>
            <PricingPlanReceiptSummary
                constants={constants}
                plan={plan}
                userId={user.id}
                transactionId={transactions.data.id}
                status={transactions.data.status}/>
            { balance.balance &&
                <Flex w={"full"} justify={"space-around"}><SplitPaymentCheckoutForm
                    transaction={transactions.data}
                    balance={ balance }
                    user={ user }
                /></Flex>
            }
        </VStack>

    )
}

export default TransactionResultView
