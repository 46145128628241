import { Center, VStack, useStyleConfig } from "@chakra-ui/react"
import { BeatLoader } from "react-spinners"
import { useAuth } from "../contexts/AuthProvider"
import { useConstants } from "../../contexts/ConstantsProvider"
// import Logo from "../../components/Logo"

import { isNull, isUndefined, isEmpty } from "lodash"
import { useUI } from "../../contexts/UIProvider"

export const AuthLoading = ({ children }) => {
    let { currentUser, claims } = useAuth()
    let constants = useConstants()
    const { variant, logo } = useUI()
    const styles = useStyleConfig("AuthLoading", { variant })
    const loaderStyles = useStyleConfig("BeatLoader", { variant })

    let { requiredClaims } = constants
    let currentUserHasntLoaded = isUndefined(currentUser)
    let doesntRequireClaims = isEmpty(requiredClaims)
    let requiresClaims = !doesntRequireClaims
    let claimsHaventLoaded = isNull(claims)

    // console.log("AuthLoading", currentUser, claims,
    // currentUserHasntLoaded, currentUser && doesntRequireClaims,
    // (currentUser && !isEmpty(requiredClaims) && isNull(claims)))

    return (currentUserHasntLoaded ||
        (currentUser && requiresClaims && claimsHaventLoaded))
        ?
        <Center direction={"column"} h={"100vh"} __css={styles}>
            <Center>
                <VStack >
                    {/*<Logo />*/}
                    { logo }
                    <BeatLoader
                        {...loaderStyles}
                        // color={"var(--chakra-colors-brand-500)"}
                    />
                </VStack>
            </Center>
        </Center>
        : children
        ;
}

export default AuthLoading
