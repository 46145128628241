import Collection from "../Collection"
import { ImPriceTags } from "react-icons/im"
import * as yup from "yup"
import utils from "../../utils"
import { formatInnopayDate } from "../../utils/dates"
import { template } from "lodash"

const { validation, encoding } = utils
const { encode64, md5 } = encoding


export class TransactionRequests extends Collection {
    constructor(props) {
        super(props)

        this.name = "transactionRequests"
        this.title = "Transaction Requests"
        this.modelLabel = "Transaction"

        this.path = "/transactionsRequests"
        this.section = "Collections" // Sidebar section on admin pane
        this.showOnVersions = ["admin", "superadmin"]
        this.sidebarIcon = <ImPriceTags/>

        this.searchKeys = []
        this.imageFields = []

        this.schema = {
            PayMethod: yup.string().default("CARD"),
            GoodsCnt: yup.number().default(1),
            GoodsName: yup.string().default(""),
            Amt: yup.number().default(1000),
            Moid: yup.string().default(""), // order1643175350
            MID: yup.string().default(""), // pgdrmore1,
            ReturnURL: yup.string().default(""), // https:// /onInnopayTransactionResult
            ResultYN: yup.string().default(""), // Y - , N -
            RetryURL: yup.string().default(""), // https:// /onInnopayTransactionResult
            mallUserID: yup.string().default(""), //
            BuyerName: yup.string().default(""), //
            BuyerTel: yup.string().default(""), //
            BuyerEmail: yup.string().default(""),
            OfferingPeriod: yup.string().default(""), //
            VbankExpDate: yup.string().default(""),
            EncodingType: yup.string().default("utf-8"),
            FORWARD: yup.string().default("N"), // Y:팝업연동 N:페이지전환

            ediDate: yup.string().default(""), // yyyyMMddHHmmss
            MerchantKey: yup.string().default(""), // config
            EncryptData: yup.string().default(""), //
            MallIP: yup.string().default(""), // 가맹점서버 IP 가맹점에서 설정
            UserIP: yup.string().default(""), // 구매자 IP 가맹점에서 설정
            MallResultFWD: yup.string().default("Y"), // Y 인 경우 PG결제결과창을 보이지 않음
            device: yup.string().default("mobile"), // "pc" or "mobile"
            BrowserType: yup.string().default(""),
            MallReserved: yup.string().default(""),



/*

            <input type="hidden" name="ediDate" value="22222222JanJan2626141401015050"> <!-- 결제요청일시 제공된 js 내 setEdiDate 함수를 사용하거나 가맹점에서 설정 yyyyMMddHHmmss-->
            <input type="hidden" name="MerchantKey" value="3KiP4xTH6J4C6oq3Yzlcw/OICBMqaqQNtaJrt2XsaanrH5Cq63hOrMQiz8DjHmxkPVNKjAaNbqLjRodE/rhwfA=="> <!-- 발급된 가맹점키 -->
            <input type="hidden" name="EncryptData" value=""> <!-- 암호화데이터 -->
		    <input type="hidden" name="MallIP" value="211.37.147.151"/> <!-- 가맹점서버 IP 가맹점에서 설정-->
		    <input type="hidden" name="UserIP" value="1.240.159.219"> <!-- 구매자 IP 가맹점에서 설정-->
		    <!-- <input type="hidden" name="FORWARD" value="Y"> Y:팝업연동 N:페이지전환 -->
            <input type="hidden" name="MallResultFWD"   value="N"> <!-- Y 인 경우 PG결제결과창을 보이지 않음 -->
		    <input type="hidden" name="device" value=""> <!-- 자동셋팅 -->
		    <!--hidden 데이타 옵션-->
		    <input type="hidden" name="BrowserType" value="">
            <input type="hidden" name="MallReserved" value="">
		    <!-- 현재는 사용안함 -->
		    <input type="hidden" name="SUB_ID" value=""> <!-- 서브몰 ID -->
            <input type="hidden" name="BuyerPostNo" value="" > <!-- 배송지 우편번호 -->
            <input type="hidden" name="BuyerAddr" value=""> <!-- 배송지주소 -->
		    <input type="hidden" name="BuyerAuthNum">
		    <input type="hidden" name="ParentEmail">*/
        }

        this.parseDates = ["expiresAt"]

        this.sortBy = [
            [ "createdAt", ],
            [ "desc", ]
        ]
    }

    getSchema(constants, sites, user, { transactionId, price }) {
        let site = sites.currentSite

        const ediDate = (() => formatInnopayDate(new Date()))()
        const MID = constants.innopay[site].MID
        const MerchantKey = constants.innopay[site].MerchantKey
        const ReturnURL = template(constants.innopay[site].ReturnURL)({ transactionId })
        const RetryURL = template(constants.innopay[site].RetryURL)({ transactionId })
        const Amt = price

        const EncryptData = this.getEncryptData({ ediDate, MID, MerchantKey, Amt })

        return yup.object().shape({
            ...this.schema,
            BuyerName: yup.string()
                .required()
                .default(user.name),
            BuyerTel: validation.koreanMobile
                .required()
                .default(user.mobile || "")
                .transform(val => val.replace(/[ -]/g, "")),
            BuyerEmail: yup.string()
                .required()
                .default(user.email),
            GoodsName: yup.string()
                .default(constants.innopay[site].purchaseItemName),
            MID: yup.string().default(MID),
            MerchantKey: yup.string().default(MerchantKey),
            ReturnURL: yup.string().default(ReturnURL),
            RetryURL: yup.string().default(RetryURL),

            Amt: yup.number()
                // eslint-disable-next-line
                .min(1000, "Minimum payment amount is ${min}")
                // eslint-disable-next-line
                .max(10000000, "Maximum payment amount is ${max}")
                .default(Amt),
            Moid: yup.string().default(transactionId), // order1643175350

            EncryptData: yup.string().default(EncryptData),
            ediDate: yup.string().default(ediDate)

        })
    }

    getEncryptData(values) {
        let required = ["MID", "Amt", "ediDate", "MerchantKey"]
        if (required.some(key => !values[key])) {
            throw new Error("EncryptData requires " + required.join(", "))
        }
        return encode64(md5(`${values.ediDate}${values.MID}${values.Amt}${values.MerchantKey}`))
    }
}

export default TransactionRequests
