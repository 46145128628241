import * as React from "react"
import PasswordControl from "./PasswordControl";
import { Box, useDisclosure } from "@chakra-ui/react";

export const PasswordConfirmControl = (props) => {
    let { passwordName, passwordLabel, confirmName, confirmLabel, formik} = props
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true})
    return (<Box>
        <PasswordControl name={passwordName} label={passwordLabel}
                         isGlobalOpen={ isOpen } onGlobalToggle={ onToggle } formik={formik}/>
        <PasswordControl name={confirmName} label={confirmLabel}
                         isGlobalOpen={ isOpen } onGlobalToggle={ onToggle } formik={formik}/>
    </Box>
    )
}

export default PasswordConfirmControl
