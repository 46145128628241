import { Box, useColorModeValue, useStyleConfig } from '@chakra-ui/react'

export const Card = (props) => {

    const { variant, ...rest } = props
    const styles = useStyleConfig("Card", { variant : (variant || "default") })

    // console.log(styles)

    return (
        <Box
            className={"card"}
            bg={useColorModeValue('white', 'gray.700')}
            py="6"
            maxWidth="2xl"
            mx="auto"
            px={{
                base: '4',
                md: '10',
            }}
            shadow="base"
            rounded={{
                base: 'md',
            }}
            {...styles}
            {...rest}
        />
    )
}

export default Card