import {
    extendTheme,
    withDefaultColorScheme,
} from '@chakra-ui/react'
import colors from "./foundations/colors"
import { globalStyles } from "./global"
import { components } from "./components"

export const getBaseTheme = () => {
    return extendTheme(globalStyles, { colors }, {
        fonts: {
            heading: '-apple-system, BlinkMacSystemFont, "Noto Sans KR", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            body: '-apple-system, BlinkMacSystemFont, "Noto Sans KR", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
        }
    })
}

export const createCustomTheme = (baseTheme) => {
    return extendTheme(
        baseTheme,
        // { styles: {
        //         global: {
        //             ...baseTheme.styles.global,
        //             ...globalStyles.styles.global
        //         }
        //     }},
        { components },
        withDefaultColorScheme({
            colorScheme: "brand"
        }))
}