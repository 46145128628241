import * as React from "react"

import {
    Flex,
    FormControl,
    FormLabel, IconButton, Input,
    InputGroup,
    InputRightElement,
    useDisclosure,
    useMergeRefs,
    FormErrorMessage,
    FormHelperText, Box,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { useField } from "formik"
import { Link as NavLink } from "react-router-dom";

export const PasswordControl = React.forwardRef((props, ref) => {

    const { name, label, helperText, errorMessageProps, helperTextProps,
        onGlobalToggle, isGlobalOpen, formik, forgotLink, ...rest } = props

    let { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })
    const inputRef = React.useRef(null)
    const mergeRef = useMergeRefs(inputRef, ref)
    let [field, { error, touched }] = useField(name)


    // for confirm password double field
    isOpen = !!onGlobalToggle ? isGlobalOpen : isOpen
    onToggle = !!onGlobalToggle ? onGlobalToggle : onToggle


    const onClickReveal = () => {
        onToggle()
        const input = inputRef.current

        if (input) {
            input.focus({
                preventScroll: true,
            })
            const length = input.value.length * 2
            requestAnimationFrame(() => {
                input.setSelectionRange(length, length)
            })
        }
    }

    return (
        <FormControl id={ name } isInvalid={!!error && touched} mb={4}>
            { label && <Flex justify="space-between">
                <FormLabel>{ label }</FormLabel>
                { forgotLink && <Box as={ NavLink } to={forgotLink}
                                     color={mode('brand.600', 'brand.200')}
                                     fontWeight="semibold" fontSize="sm">
                    Forgot Password?
                </Box>}
            </Flex> }
            <InputGroup>
                <InputRightElement>
                    <IconButton
                        bg="transparent !important"
                        variant="ghost"
                        aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                        icon={isOpen ? <HiEyeOff /> : <HiEye />}
                        onClick={onClickReveal}
                    />
                </InputRightElement>
                <Input
                    { ...field }
                    ref={mergeRef}
                    name={name}
                    type={isOpen ? 'text' : 'password'}
                    autoComplete="current-password"
                    // required
                    {...rest}
                />
            </InputGroup>
            {error && (
                <FormErrorMessage {...errorMessageProps}>{error}</FormErrorMessage>
            )}
            {helperText && (
                <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
            )}
        </FormControl>
    )
})


PasswordControl.displayName = 'PasswordControl'

export default PasswordControl
