import * as React from "react"
import { useOutletContext, Outlet } from "react-router-dom"
import { useCollectionClass } from "../contexts/CollectionClassProvider"
import { usePaths } from "../contexts/PathsProvider"
import useFormImages from "../hooks/useFormImages"
import { getDocs, getFuego, setDoc, updateDoc } from "swr-firestore-v9"
import { isEmpty, omit, pick } from "lodash"

export const FormPage = ({ children, ...props }) => {

    const collection = useCollectionClass()
    const context = useOutletContext()
    const fuego = getFuego()

    // console.log("formpage", context)
    const paths = usePaths()
    let { isNew } = paths

    let images /*{ previews, setPreviewField, handleImageUploads }*/ = useFormImages(collection)

    const safeDocs = (docs, key) => {
        if (docs && docs[key]) return docs
        return {
            ...(docs || {}),
            [key]: { data: undefined, updateDoc, loading: true }
        }
    }

    const safeCols = (cols, ...keys) => {
        if (cols && keys.every(key => cols[key])) return cols
        return keys.reduce((obj, key) => {
            obj[key] = { data: [], add: setDoc, loading: true }
            return obj
        }, {})
    }


    const isUnique = async (collection, field, value) => {
        try {
            let data = await getDocs(collection.name, {
                where: [ field, "==", value ],
                limit: 1
            })
            // console.log(data)
            return !(data.length > 0)
        } catch (err) {
            console.log(err)
        }
    }

    const onFieldBlurEnsureUnique = (field, error) => props => event => {
        isUnique(collection, field, props.values[field]).then(unique => {
            if (!unique) props.setFieldError(field, error)
        })
        props.handleBlur(event)
    }

    const onDocUpdated = async (doc, updateDocFunc) => {
        // if (isEmpty(images.previews)) return
        updateDocFunc = updateDocFunc || (data => updateDoc(`${collection.name}/${doc.id}`, data))
        let update = {}
        try {
            let imageUrls = await images.handleImageUploads(doc.id, pick(doc, collection.imageFields))
            console.log("imageUrls", imageUrls, !isEmpty(imageUrls))
            if (!isEmpty(imageUrls)) update = {
                ...update,
                ...imageUrls
            }
            console.log("onDocUpdated updating with", omit(update, fuego.protectedFields))
            await updateDocFunc(omit(update, fuego.protectedFields))
        } catch (err) {
            console.log(err)
        }
    }

    const onDocCreated = onDocUpdated
    const onDocCreatedOrUpdated = onDocUpdated

    const handleSubmit = (schema, doc, add, updateDoc) => async (values, { setSubmitting }) => {

        setSubmitting(true)
        let data = schema.cast(values)
        let id = doc?.id
        try {
            if (!id) {
                id = await add([data])
                console.log("add result", id)
                await onDocCreatedOrUpdated({ ...data, id }, updateDoc)

            } else {
                await updateDoc({
                    ...data,
                    updatedAt: new Date()
                })
                await onDocCreatedOrUpdated(data, updateDoc)
            }

        } catch (err) {
            console.log(err)
            setSubmitting(false)
        }
    }

    const newContext = {
        ...context,
        ...props,
        collection,
        paths,
        isNew,
        images,
        safeDocs,
        safeCols,
        handleSubmit,
        onDocCreated,
        onDocUpdated,
        onDocCreatedOrUpdated,
        onFieldBlurEnsureUnique
    }

    if (children) {
        const childrenWithProps = React.Children.map(children, child => {
            // Checking isValidElement is the safe way and avoids a typescript
            // error too.
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { ...newContext });
            }
            return child;
        });

        return <div>{childrenWithProps}</div>
    }

    return <Outlet context={newContext}/>

}

export default FormPage
