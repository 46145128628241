import { TabIndicator, TabList, Tabs, useColorModeValue as mode } from "@chakra-ui/react"
import * as React from "react"

export const MobileTabs = ({ logoLoaded, tabIndex, onTabChange, darkBg, children }) => {
    let bg = darkBg ? ["white", "whiteAlpha.800"] : ["brand.500", "brand.200"]

    const [loadIndicator, setLoadIndicator] = React.useState(logoLoaded)
    React.useEffect(() => setLoadIndicator(logoLoaded), [logoLoaded])

    return (
        <Tabs orientation="vertical" variant="unstyled"
              index={tabIndex}
              onChange={onTabChange}>
            <TabList w={"full"} >{children}</TabList>
            <TabIndicator
                key={loadIndicator}
                marginStart="-3"
                width={1}
                borderTopRadius={{
                    base: 'none',
                    md: 'md',
                }}
                bg={mode(...bg)}
            />
        </Tabs>
    )
}

export default MobileTabs
