import auth from "../auth/config"
import LocalizedStrings from "react-localization"

export const config = {
    versions: ["site", "members", "shop", "subscribers", "shopowner",
        "manager", "teacher", "admin", "superadmin"],
    theme: "blue",
    auth,
    requiredClaims: [],
    currentUserCol: "users",
    currentUserParseDates: ["createdAt", "updatedAt"],
    fuego: {
        protectedFields: ["__snapshot", "id", "exists", "hasPendingWrites"],
        callables: ["ensureEmailUnique", "createMemberUser", "ensureMobileUnique"],
        useEmulator: false,
        emulatorHost: ["localhost", 5001],

    },
    markets: [
        { name: "ElMarket", label: "엘마켓" },
        { name: "Mozzimong", label: "모찌몽베베" }
    ],
    sites: [
        { value: "dreammore", label: "dream-more.kr", name: "꿈을더하다" },
        { value: "dreamupup", label: "dream-upup.kr", name: "꿈을키우다" },
    ],
    innopay: {
        paymentURL: "https://pg.innopay.co.kr/pay/interfaceURL.jsp",
        dreammore: {
            purchaseItemName: "꿈을더하다 패키지 결제",
            MID: "pgdrmore1m",
            MerchantKey: "0O1pydSezMWxe7YYlCn0/4KR9yRnQR9iNfHewJYKbHjfKdcor/IPr/FWfGJ1XvHUoG1NDHiAFbhUkec/KlV83g==",
            // ReturnURL: `https://${window.location.host}/transactions/<%= transactionId %>/view`, // add id  /view
            RetryURL : `https://${window.location.host}/transactions/<%= transactionId %>/view`, // add id + /view
            ReturnURL: `https://${window.location.host}/innopay/result/<%= transactionId %>`, // add id  /view
            // RetryURL : `https://${window.location.host}/innopay/result/<%= transactionId =>`, // add id + /view
        },
        notifiedKeys: {
            paid: "approvalAmt",
            cancelled: "cancelAmt"
        },
        resultKeys: {
            paid: "Amt",
            cancel: "pgApprovalAmt",
            code: "ResultCode"
        },
        resultCodes: {
            cancel: "2001",
            paid: "3001"
        }
    },
    strings: {
        en: {
            auth: { ...auth.strings.en },
            validation: {
                required: "Required",
            },
            signout: "Sign Out",
            dreammore: {
                payment: {
                    purchaseItemName: "꿈을더하다 패키지 결제"
                }
            },
            site: {
                navbar: {
                    signupForFree: "Sign up for free!"
                },
            },
            members: {
                accountSettings: {
                    basicSettingsTitle: "Account Settings",
                    basicSettingsDescription: "Change your profile, request your data, and more",
                    changePassword: "Change Password",
                    changeSignature: "Change Signature"
                },
                memberConfirmation: {
                    title: "Membership Confirmation",
                    description: "Take a screenshot of this screen to show confirmation of your membership",
                    agreementToTermsAndConditions: "I read and agreed to Terms & Conditions",
                    agreementToPrivacyPolicy: "I read and agreed to Privacy Policy",
                    agreementToRefundPolicy: "I read and agreed to Refund Policy",
                    agreementSignedAt: "Agreement signed at: "
                },
                pricingPlans: {
                    submitButtonLabel: "Get Started",
                    mostPopularLabel: "Most Popular"
                },
                signupForm: {

                    backButton: "Back",
                    nextButton: "Next",
                    clearButton: "Clear",

                    basicInfoTitle: "Basic Information",
                    nameLabel: "Name",
                    emailLabel: "Email",
                    emailHelperText: "This email will be used to login",
                    emailExists: "Email already exists",
                    mobileLabel: "Mobile Phone Number",
                    mobileExists: "Mobile number already exists",

                    passwordTitle: "Login & Password",
                    passwordLabel: "Password",
                    confirmLabel: "Confirm Password",

                    personalInfoTitle: "Personal Information",
                    birthdayLabel: "Birthday",
                    genderLabel: "Gender",
                    male: "Male",
                    female: "Female",

                    termsTitle: "Terms & Conditions",
                    termsLabel: "Terms",
                    personalInfoCollectionLabel: "Privacy Policy",
                    refundPolicyLabel: "Refund Policy",
                    iAgree: "I agree",

                    signatureTitle: "Signature",
                    signatureDescription: "Write your name here to acknowledge that you have read and agreed to our terms and conditions and refund policy",
                    signatureConfirmationTitle: "Confirm Signature",
                    signatureConfirmationDescription: "Confirm that this is your signature to complete registration",
                    signatureConfirmation: "I confirm that this is my signature",

                    joinButton: "Join now!",
                    signupErrorMessage: "Sorry. Something went wrong creating your account. Please talk to a manager."
                },
                checkout: {
                    orderSummary: "Order Summary",
                    promotionCodePlaceholder: "Discount Code",
                    subtotal: "Subtotal",
                    orderTotal: "Order Total",
                    placeOrderButton: "Place Order",
                    placingOrderLoading: "Placing Order...",

                    promoCodeNotFound: "Promo not found.",

                    billing: {
                        title: "Billing Information",
                        name: "Name",
                        mobile: "Mobile Phone",
                        email: "Email"
                    },

                    orderStatusTitle: "Order Status",
                    orderSuccessMessage: "That's so awesome! You're now part of the Dream Family!!",
                    orderConfirmationId: "Confirmation No.",
                    orderState: {
                        PAID: "Paid",
                        PARTIAL_PAID: "Partial Paid",
                        PROCESSING: "Processing",
                        CANCELED: "Cancelled",
                        CANCELLED: "Cancelled",
                        ERROR: "Error",
                        ABORTED: "Aborted"
                    },
                    orderStatusDescription: {
                        PAID: "Order complete",
                        PARTIAL_PAID: "Partial payment complete",
                        PROCESSING: "Processing order...",
                        CANCELED: "Order cancelled",
                        CANCELLED: "Order cancelled",
                        ERROR: "ERROR. Something went wrong.",
                        ABORTED: "This transaction was aborted."
                    },
                    goToProfile: "Go To Profile",

                    splitPayments: "Split Payments",
                    splitPlaymentAmt: "Payment Amount",
                    partialPaymentLabel: "Partial Payments",

                }
            }
        },
        ko: {
            auth: { ...auth.strings.ko },
            validation: {
                required: "필수",
            },
            signout: "로그아웃",
            site: {
                navbar: {
                    signupForFree: "무료로 가입 하세요!"
                },
            },
            members: {

                accountSettings: {
                    basicSettingsTitle: "Account Settings",
                    basicSettingsDescription: "Change your profile, request your data, and more",
                    changePassword: "비밀번호 변경",
                    changeSignature: "Change Signature"
                },
                memberConfirmation: {
                    title:"회원가입 확인란",
                    description: "약관 및 정책 확인 후 서명하시고 본 화면을 스크린 샷으로 저장 후 상담원에서 보여주세요. ",
                    agreementToTermsAndConditions: "본 약관을 읽었으며 이에 동의합니다.",
                    agreementToPrivacyPolicy: "본 개인정보 정책을 읽었으며 이에 동의합니다.",
                    agreementToRefundPolicy: "본 환불규정을 읽었으며 이에 동의합니다.",
                    agreementSignedAt: "동의서명 시각: "
                },
                pricingPlans: {
                    submitButtonLabel: "결제하러 가기",
                    mostPopularLabel: "BEST ITEM"
                },
                signupForm: {
                    backButton: "뒤로가기",
                    nextButton: "다음",
                    clearButton: "초기화",

                    basicInfoTitle: "정보를 입력해주세요",
                    nameLabel: "이름",
                    emailLabel: "이메일",
                    emailHelperText: "본 메일로 교재가 발송되니 정확히 입력해주세요",
                    emailExists: "이미 등록되어 있는 이메일 입니다.",
                    mobileLabel: "휴대폰 번호",
                    mobileExists: "이미 등록되어 있는 휴대폰 번호입니다.",

                    passwordTitle: "로그인 정보 입력하기",
                    passwordLabel: "비밀번호",
                    confirmLabel: "비밀번호 확인",

                    personalInfoTitle: "개인정보 입력",
                    birthdayLabel: "생년월일",
                    genderLabel: "성별",
                    male: "남성",
                    female: "여성",

                    termsTitle: "이용약관 및 개인정보 수집 동의",
                    termsLabel: "이용약관",
                    personalInfoCollectionLabel: "개인정보 수집 및 이용동의",
                    refundPolicyLabel: "환불규정 및 교육연기",
                    iAgree: "동의합니다",

                    signatureTitle: "서명",
                    signatureDescription: "회원등록을 완료하기 위하여 성명을 그래로 따라써주세요.",

                    signatureConfirmationTitle: "서명 등록",
                    signatureConfirmationDescription: "귀하가 당사 약관 및 환불정책을 읽고 동의했음을 확인하기 위하여 귀하의 이름을 정자로 따라 써주세요.",
                    signatureConfirmation: "본인의 서명임을 확인합니다.",

                    joinButton: "가입하기",
                    signupErrorMessage: "앗! 문제가 생겼어요. 화면을 캡쳐해서 담당자에게 전달해주세요."
                },
                checkout: {
                    orderSummary: "결제 플랜",
                    promotionCodePlaceholder: "할인코드",
                    subtotal: "교육비용",
                    orderTotal: "총 결제금액",
                    placeOrderButton: "결제하기",

                    promoCodeNotFound: "코드 오류",

                    billing: {
                        title: "결제정보",
                        name: "이름",
                        mobile: "휴대폰 번호",
                        email: "이메일"
                    },

                    orderStatusTitle: "결제 상태",
                    orderSuccessMessage: "That's so awesome! You're now part of the Dream Family!!",
                    orderConfirmationId: "결제확인 번호",
                    orderState: {
                        PAID: "결제완료",
                        PARTIAL_PAID: "부분결제",
                        PROCESSING: "결제중",
                        CANCELED: "결제취소",
                        CANCELLED: "결제취소",
                        ERROR: "에러"
                    },
                    orderStatusDescription: {
                        PAID: "결제완료",
                        PARTIAL_PAID: "부분결제완료",
                        PROCESSING: "잠시만 기다려주세요",
                        CANCELED: "결제가 취소되었습니다",
                        CANCELLED: "결제가 취소되었습니다",
                        ERROR: "에러. 화면을 캡쳐해주세요"
                    },
                    goToProfile: "프로필 바로가기",

                    splitPayments: "멀티결제",
                    splitPlaymentAmt: "결제할 금액",
                    partialPaymentLabel: "결제된 금액",

                    placingOrderLoading: "Placing Order...",

                }
            }
        },
    }
}

export const getConstants = () => {
    return {
        ...config,
        strings: new LocalizedStrings(config.strings),
        auth: {
            ...config.auth,
            strings: new LocalizedStrings(config.auth.strings)
        }
    }
}