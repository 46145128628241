import { Box, useStyleConfig } from "@chakra-ui/react"

export const MembersPageLayout = ({ children }) => {

    let styles = useStyleConfig("PageLayout", { variant: "dreammore"})

    // console.log("styles", styles)
    return (
        <Box
            className={"page-layout"}
            __css={styles}
            px={["2", null, "6"]}
            py={"6"}
        >
            { children }
        </Box>
    )
}

export default MembersPageLayout
