import * as React from "react"
import { Formik, Form } from "formik"
import { nanoid } from "nanoid"
import { InputControl, RadioGroupControl, CheckboxSingleControl, SubmitButton } from "formik-chakra-ui"
import { PhoneControl } from "../../components/forms/PhoneControl"
import {
    Box,
    Radio,
    HStack,
    Stack,
    Button,
    FormControl,
    FormLabel,
    Flex,
    Text,
    FormHelperText,
    Image, FormErrorMessage, Center
} from "@chakra-ui/react"
import { getFuego } from "swr-firestore-v9"
import { Card } from "../../components/base/Card"
import LoginError from "../../auth/components/LoginError"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
// import validationSchema, { getDefault, toUser, toAuthUser } from "./schema"

import { Step } from '../../components/steps/Step'
import { StepContent } from '../../components/steps/StepContent'
import { Steps } from '../../components/steps/Steps'
import { useSteps } from '../../components/steps/useSteps'

import PasswordConfirmControl from "../../components/forms/PasswordConfirmControl"
import {
    // useLocation,
    useNavigate } from "react-router-dom"

import { useAuth } from "../../auth/contexts/AuthProvider"
import { useConstants } from "../../contexts/ConstantsProvider"
import { useCollectionClasses } from "../../contexts/CollectionClassesProvider"
import { useTermsAndConditions } from "../../hooks/use-terms-and-conditions"
import { TermsContainer } from "../../components/terms/terms-container"
import SignatureCanvas from "react-signature-canvas"
import uploadDataUrl from "../../firebase/uploadDataUrl"
import { TfiReload } from "react-icons/tfi"
import { collection, query, where, limit, getDocs } from "firebase/firestore"


export const SignupForm = () => {

    const fuego = getFuego()
    const auth = useAuth()
    const constants = useConstants()
    const { signup } = useCollectionClasses()
    const schema = signup.getSchema()
    const initialValues = signup.getDefault()
    const signatureRef = React.useRef({})
    const [ signatureStarted, setSignatureStarted ] = React.useState(false)
    const [ showSignaturePad, setShowSignaturePad ] = React.useState(false)
    // console.log("initialValues", initialValues)

    const strings = constants.strings.members.signupForm
    const termsAndConditions = useTermsAndConditions()

    const navigate = useNavigate()
    // const location = useLocation()
    // const from = location.state?.from?.pathname || "/"

    const { nextStep, prevStep, activeStep, goToStep } = useSteps({
        initialStep: 0,
    })

    const [ submittingStep, setSubmittingStep ] = React.useState(false)
    const [ signupError, setSignupError ] = React.useState(null)

    React.useEffect(() => {
        const handler = () => setSignatureStarted(false)
        window.addEventListener("resize", handler)
        return () => window.removeEventListener("resize", handler)
    })

    React.useEffect(() => {
        setShowSignaturePad(activeStep === 4)
    }, [ activeStep ])

    // React.useEffect(() => {
    //     if (confirmSignatureRef.current.fromDataURL) confirmSignatureRef.current.fromDataURL(signatureRef.current.getCanvas().toDataURL('image/png'))
    // }, [ signatureRef.current ])

    const onBasicInfoSubmit = async (formik) => {
        let { values, errors, setFieldError } = formik
        setSubmittingStep(true)
        const fields = [ "name", "mobile" ]

        const confirmUnique = async () => {
            const { db } = fuego
            const q = query(collection(db, "users"), where("mobile", "==", values.mobile), limit(1))
            const snapshot = await getDocs(q)
            // console.log(snapshot.docs)

            if (snapshot.docs.length) {
                setFieldError("mobile", strings.mobileExists)
                goToStep(0)
                return false
            }
            return true

            // try {
            //     let result = await fuego.callable.ensureMobileUnique({
            //         mobile: values.mobile
            //     })
            //     if (result.data.exists) {
            //         setFieldError("mobile", strings.mobileExists)
            //         goToStep(0)
            //         return false
            //     }
            // } catch (err) {
            //     console.log(err)
            // }
            // return true
        }

        if (!hasFieldErrors(values, errors, fields)) {
            const isUnique = await confirmUnique()
            if (isUnique) {
                nextStep()
            }

        }
        setSubmittingStep(false)

    }

    const hasFieldErrors = (values, errors, fields) => (
        fields.some(field => !values[field]) || fields.some(field => errors[field]))


    const onPasswordSubmit = async ({ values, errors, setFieldError }) => {
        const fields = [ "email", "password", "confirm" ]
        setSubmittingStep(true)

        const confirmUnique = async () => {
            const { db } = fuego
            const q = query(collection(db, "users"), where("email", "==", values.email), limit(1))
            const snapshot = await getDocs(q)
            // console.log(snapshot.docs)

            if (snapshot.docs.length) {
                setFieldError("email", strings.emailExists)
                goToStep(1)
                return false
            }
            return true

            // try {
            //     let result = await fuego.callable.ensureEmailUnique({
            //         email: values.email
            //     })
            //     if (result.data.exists) {
            //         setFieldError("email", strings.emailExists)
            //         goToStep(1)
            //         return false
            //     }
            // } catch (err) {
            //     console.log(err)
            // }
            // return true
        }

        if (!hasFieldErrors(values, errors, fields)) {
            const isUnique = await confirmUnique()
            if (isUnique) {
                nextStep()
            }
        }
        setSubmittingStep(false)
    }

    const onPersonalInfoSubmit = ({ values, errors }) => {
        const fields = [ "gender", "birthday" ]
        setSubmittingStep(true)
        if (!hasFieldErrors(values, errors, fields)) {
            nextStep()
        }
        setSubmittingStep(false)
    }

    const onAcceptTermsSubmit = ({ values, errors }) => {
        const fields = [ "agreeTerms", "agreePersonalInfoCollection", "agreeRefundPolicy" ]
        setSubmittingStep(true)
        if (!hasFieldErrors(values, errors, fields)) {
            nextStep()
        }
        setSubmittingStep(false)
    }

    const handleClearSignature = ({ setFieldValue }) => {
        if (signatureRef.current.clear) signatureRef.current.clear()
        setFieldValue("signature", "")
        setSignatureStarted(false)
    }

    const onSignatureSubmit = async ({ values, setFieldValue, setFieldError }) => {
        setSubmittingStep(true)
        const dataURL = signatureRef.current.getCanvas().toDataURL('image/png')
        const randomString = nanoid()
        const downloadUrl = await uploadDataUrl(dataURL, `signatures/${randomString}.png`, fuego.storage)
        setFieldValue("signature", downloadUrl)

        nextStep()
        setSubmittingStep(false)
    }

    const onSubmit = async (values, props) => {
        let { setSubmitting } = props

        setSubmitting(true)
        let authUser = signup.toAuthUser(values)
        let user = signup.toUser(values)

        try {
            const result = await fuego.callable.createMemberUser({
                authUser,
                user
            })
            console.log(result, result.data, result.data.id)
            auth.signin(authUser.email, authUser.password,
                () => navigate(`/users/${result.data.id}/view`, { replace: true }),
                err => {
                    setSignupError(err)
                }
            )
        } catch (err) {
            // console.log(err)
            setSignupError({ message: strings.signupErrorMessage })
            setSubmitting(false)
            nextStep()
        }
    }

    return (
        <Box
            mx="auto"
            maxW="3xl"
            pb="10"
            px={{
                base: '0',
                md: '8',
            }}
            minH="400px"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={schema}>{
                props => (
                    <Box as={Form}>
                        <Steps activeStep={activeStep}>
                            <Step title={strings.basicInfoTitle}>
                                <StepContent ps={2}>
                                    <Card px={{
                                        base: '4',
                                        md: '6',
                                    }}>
                                        <Stack shouldWrapChildren spacing="4">
                                            <InputControl
                                                name={"name"}
                                                label={strings.nameLabel}
                                                // inputProps={{ variant: "dreammore"}}
                                            />
                                            <PhoneControl
                                                name={"mobile"}
                                                inputProps={{ type: "tel", focusBorderColor: "secondary.500" }}
                                                label={strings.mobileLabel}
                                            />

                                            <HStack justify={"flex-end"} mr={2}>
                                                <Button
                                                    size="md"
                                                    variant="ghost"
                                                    isDisabled
                                                    bg={"gray.50"}
                                                    colorScheme={"secondary"}
                                                >
                                                    {strings.backButton}
                                                </Button>
                                                <Button
                                                    isDisabled={!(props.touched.name || props.touched.mobile)}
                                                    colorScheme={"primary"}
                                                    size="md"
                                                    isLoading={submittingStep}
                                                    onClick={() => onBasicInfoSubmit(props)}>
                                                    {strings.nextButton}
                                                </Button>
                                            </HStack>
                                        </Stack>
                                    </Card>

                                </StepContent>
                            </Step>
                            <Step title={strings.passwordTitle}>
                                <StepContent ps={2}>
                                    <Card px={{
                                        base: '4',
                                        md: '6',
                                    }}>
                                        <Stack shouldWrapChildren spacing="4">
                                            <InputControl
                                                name={"email"}
                                                inputProps={{ type: "email" }}
                                                label={strings.emailLabel}
                                                helperText={strings.emailHelperText}
                                            />
                                            <PasswordConfirmControl
                                                formik={props}
                                                passwordName={"password"}
                                                passwordLabel={strings.passwordLabel}
                                                confirmName={"confirm"}
                                                confirmLabel={strings.confirmLabel}
                                            />
                                            <HStack justify={"flex-end"} mr={2}>
                                                <Button
                                                    size="md"
                                                    onClick={prevStep}
                                                    colorScheme={"secondary"}
                                                    variant="ghost">
                                                    {strings.backButton}
                                                </Button>
                                                <Button
                                                    colorScheme={"primary"}
                                                    size="md"
                                                    isLoading={submittingStep}
                                                    onClick={() => onPasswordSubmit(props)}>
                                                    {strings.nextButton}
                                                </Button>
                                            </HStack>
                                        </Stack>
                                    </Card>
                                </StepContent>
                            </Step>
                            <Step title={strings.personalInfoTitle}>
                                <StepContent ps={2}>
                                    <Card px={{
                                        base: '4',
                                        md: '6',
                                    }}>
                                        <Stack shouldWrapChildren spacing="4">
                                            <RadioGroupControl
                                                name={"gender"}
                                            >
                                                <Radio value={"female"}>{strings.female}</Radio>
                                                <Radio value={"male"}>{strings.male}</Radio>
                                            </RadioGroupControl>
                                            <InputControl
                                                inputProps={{ type: "date" }}
                                                name={"birthday"}
                                                label={strings.birthdayLabel}/>

                                            <HStack justify={"flex-end"} mr={2}>
                                                <Button
                                                    size="md"
                                                    colorScheme={"primary"}
                                                    onClick={prevStep}
                                                    variant="ghost">
                                                    {strings.backButton}
                                                </Button>
                                                <Button
                                                    size="md"
                                                    isLoading={submittingStep}
                                                    onClick={() => onPersonalInfoSubmit(props)}>
                                                    {strings.nextButton}
                                                </Button>
                                            </HStack>
                                        </Stack>
                                    </Card>
                                </StepContent>
                            </Step>
                            <Step title={strings.termsTitle}>
                                <StepContent ps={2}>
                                    <Card px={{
                                        base: '4',
                                        md: '6',
                                    }}>
                                        <Stack shouldWrapChildren spacing="4">
                                            <FormControl>
                                                <FormLabel>{strings.termsLabel}</FormLabel>
                                                <TermsContainer
                                                    borderColor={"gray.500"}
                                                    borderStyle={"solid"}
                                                    borderWidth={"1px"}
                                                    overflowY={"auto"}
                                                    maxH={"200px"}
                                                    mb={2}
                                                >
                                                    {termsAndConditions.termsAndConditions && <ReactMarkdown
                                                        rehypePlugins={[ rehypeRaw ]}
                                                        children={termsAndConditions.termsAndConditions}
                                                    />}
                                                </TermsContainer>
                                                {/*<Textarea rows={6} mb={2} defaultValue={termsAndConditions.agreement} readOnly/>*/}
                                                <CheckboxSingleControl
                                                    as={Flex}
                                                    justify={"space-between"}
                                                    name={"agreeTerms"}
                                                    checkBoxProps={{ size: "lg", borderColor: "primary.400" }}
                                                    errorMessageProps={{ marginTop: 0 }}
                                                    labelProps={{ display: "flex", mt: "0" }}
                                                >
                                                    {strings.iAgree}
                                                </CheckboxSingleControl>


                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>{strings.personalInfoCollectionLabel}</FormLabel>
                                                {/*<Textarea rows={6} mb={2} defaultValue={termsAndConditions.personalData} readOnly/>*/}
                                                <TermsContainer
                                                    borderColor={"gray.500"}
                                                    borderStyle={"solid"}
                                                    borderWidth={"1px"}
                                                    overflowY={"auto"}
                                                    maxH={"200px"}
                                                    mb={2}
                                                >
                                                    {termsAndConditions.useOfPersonalInformation && <ReactMarkdown
                                                        rehypePlugins={[ rehypeRaw ]}
                                                        children={termsAndConditions.useOfPersonalInformation}
                                                    />}
                                                </TermsContainer>
                                                <CheckboxSingleControl
                                                    as={Flex}
                                                    justify={"space-between"}
                                                    name={"agreePersonalInfoCollection"}
                                                    checkBoxProps={{ size: "lg", borderColor: "primary.400" }}
                                                    errorMessageProps={{ marginTop: 0 }}
                                                    labelProps={{ display: "flex", mt: "0" }}
                                                >

                                                    {strings.iAgree}
                                                </CheckboxSingleControl>

                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>{strings.refundPolicyLabel}</FormLabel>
                                                {/*<Textarea rows={6} mb={2} defaultValue={termsAndConditions.refundPolicy} readOnly/>*/}
                                                <TermsContainer
                                                    borderColor={"gray.500"}
                                                    borderStyle={"solid"}
                                                    borderWidth={"1px"}
                                                    overflowY={"auto"}
                                                    maxH={"600px"}
                                                    mb={2}
                                                >
                                                    {termsAndConditions.refundPolicy && <ReactMarkdown
                                                        rehypePlugins={[ rehypeRaw ]}
                                                        children={termsAndConditions.refundPolicy}
                                                    />}
                                                </TermsContainer>
                                                <CheckboxSingleControl
                                                    as={Flex}
                                                    justify={"space-between"}
                                                    name={"agreeRefundPolicy"}
                                                    checkBoxProps={{ size: "lg", borderColor: "primary.400" }}
                                                    errorMessageProps={{ marginTop: 0 }}
                                                    labelProps={{ display: "flex", mt: "0" }}
                                                >

                                                    {strings.iAgree}
                                                </CheckboxSingleControl>

                                            </FormControl>

                                            <HStack justify={"flex-end"} mr={2} w={"full"}>

                                                <Button size="md" onClick={prevStep} variant="ghost">
                                                    {strings.backButton}
                                                </Button>
                                                <Button
                                                    size="md"
                                                    isLoading={submittingStep}
                                                    onClick={() => onAcceptTermsSubmit(props)}>
                                                    {strings.nextButton}
                                                </Button>


                                            </HStack>
                                        </Stack>
                                    </Card>
                                </StepContent>
                            </Step>
                            <Step title={strings.signatureTitle}>
                                <StepContent ps={2}>
                                    <Card px={{
                                        base: '4',
                                        md: '6',
                                    }}>
                                        <Stack shouldWrapChildren spacing="4">
                                            <FormControl>
                                                <FormHelperText pb={4}>
                                                    {strings.signatureDescription}
                                                </FormHelperText>
                                                <Box m={0} mx={"auto"} height={"200px"} borderColor={"gray.300"}
                                                     borderStyle={"solid"} borderWidth={"1px"}
                                                     position={"relative"}
                                                >
                                                    <Box position={"absolute"} top={0} left={0} right={0} bottom={0}>

                                                        <Center h={"full"}>
                                                            <Text fontSize={"7xl"} color={"gray.200"}
                                                                  letterSpacing={"widest"}>{props.values.name}</Text>
                                                        </Center>
                                                    </Box>

                                                    {showSignaturePad && <SignatureCanvas
                                                        canvasProps={
                                                            {
                                                                style: {
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    position: "absolute",
                                                                    top: 0,
                                                                    left: 0
                                                                }
                                                            }
                                                        }
                                                        onBegin={() => {
                                                            setSignatureStarted(true)
                                                        }}
                                                        clearOnResize={false}
                                                        ref={(ref) => {
                                                            signatureRef.current = ref

                                                        }}/>}
                                                    <Box position={"absolute"} top={0} right={0} m={2}
                                                         color={"gray.400"} fontWeight={"bold"}
                                                         onClick={() => handleClearSignature(props)}>
                                                        <TfiReload size={16}/>
                                                    </Box>
                                                </Box>


                                            </FormControl>
                                            <HStack justify={"space-between"} mr={2} w={"full"}>
                                                <Box/>
                                                {/*<Button size="md" onClick={() => handleClearSignature(props)}*/}
                                                {/*        variant="ghost">*/}
                                                {/*    {strings.clearButton}*/}
                                                {/*</Button>*/}
                                                <HStack>
                                                    <Button size="md" onClick={() => {
                                                        handleClearSignature(props)
                                                        prevStep()
                                                    }} variant="ghost">
                                                        {strings.backButton}
                                                    </Button>
                                                    <Button
                                                        size="md"
                                                        isLoading={submittingStep}
                                                        isDisabled={!signatureStarted}
                                                        onClick={() => onSignatureSubmit(props)}>
                                                        {strings.nextButton}
                                                    </Button>
                                                </HStack>

                                            </HStack>
                                        </Stack>
                                    </Card>

                                </StepContent>
                            </Step>
                            <Step title={strings.signatureConfirmationTitle}>
                                <StepContent ps={2}>
                                    <Card px={{
                                        base: '4',
                                        md: '6',
                                    }}>
                                        <Stack shouldWrapChildren spacing="4">
                                            <FormControl>
                                                <FormErrorMessage>Error</FormErrorMessage>
                                                <FormHelperText pb={4}>
                                                    {strings.signatureConfirmationDescription}
                                                </FormHelperText>
                                                <Box m={0} mx={"auto"} height={"200px"} borderColor={"gray.300"}
                                                     borderStyle={"solid"} borderWidth={"1px"}
                                                     mb={2}
                                                >
                                                    {
                                                        props.values.signature ?
                                                            <Image
                                                                src={props.values.signature}
                                                                alt={"signature"}
                                                                w={"full"}
                                                                h={"auto"}
                                                            /> : null}
                                                </Box>
                                                <CheckboxSingleControl
                                                    as={Flex}
                                                    justify={"space-between"}
                                                    name={"confirmSignature"}
                                                    checkBoxProps={{ size: "lg", borderColor: "primary.400" }}
                                                    errorMessageProps={{ marginTop: 0 }}
                                                    labelProps={{ display: "flex", mt: "0" }}
                                                >
                                                    {strings.signatureConfirmation}
                                                </CheckboxSingleControl>
                                            </FormControl>
                                            <HStack justify={"flex-end"} mr={2}>
                                                <Button size="md" onClick={prevStep} variant="ghost">
                                                    {strings.backButton}
                                                </Button>
                                                <SubmitButton
                                                    colorScheme={"primary"}
                                                    isDisabled={!props.values.confirmSignature}
                                                    size="md">
                                                    {strings.joinButton}
                                                </SubmitButton>
                                            </HStack>
                                        </Stack>
                                    </Card>

                                </StepContent>
                            </Step>
                        </Steps>
                        {/*<HStack display={activeStep === lastStep ? 'flex' : 'none'} mt="10" spacing="4"*/}
                        {/*         w={"full"} boxSize={"full"}>*/}
                        {/*    <Button size="lg" w="full" onClick={reset} verticalAlign="baseline">*/}
                        {/*        { strings.joinButton }*/}
                        {/*    </Button>*/}
                        {/*</HStack>*/}
                        {signupError && <Box mt={6}><LoginError error={signupError}/></Box>}
                    </Box>
                )
            }

            </Formik>
        </Box>
    )
}

export default SignupForm
