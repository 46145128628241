import { Center, useStyleConfig } from "@chakra-ui/react"
import { RiseLoader } from "react-spinners"
import { useUI } from "../../contexts/UIProvider"

export const Loading = ({ spinnerProps, ...rest }) => {
    const { variant } = useUI()
    const styles = useStyleConfig("Loading", { variant })

    return <Center direction={"column"} {...rest}>
        <Center className={"rise-loader-center"}>
            <RiseLoader
                className={"rise-loader"}
                {...styles}
            />
        </Center>
    </Center>
}

export default Loading
