import koreanMobile from "./koreanMobile"
import confirmPassword from "./confirmPassword"
import password from "./password"
import parseDateString from "./parseDateString"
import LocalizedStrings from "react-localization"

const strings = new LocalizedStrings({
    en: {
        required: "Required",
        mustAgree: "Must agree to the terms",
        mustConfirmSignature: "You must confirm that this is your signature"
    },
    ko: {
        required: "필수",
        mustAgree: "동의 버튼을 눌러주세요.",
        mustConfirmSignature: "You must confirm that this is your signature"
    }
})

export const validation = {
    strings,
    koreanMobile,
    confirmPassword,
    password,
    parseDateString
}

export default validation
