import * as React from "react"

import termsAndConditions from "../members/terms/terms-and-conditions.md"
import refundPolicy from "../members/terms/refund-policy.md"
import privacyPolicy from "../members/terms/privacy-policy.md"
import useOfPersonalInformation from "../members/terms/use-of-personal-information.md"
import contactUs from "../members/terms/contact-us.md"

export function useTermsAndConditions() {

    const [markdown, setMarkdown] = React.useState({
        termsAndConditions: "",
        refundPolicy: "",
        privacyPolicy: "",
        useOfPersonalInformation: "",
        contactUs: ""
    });

    React.useEffect( () => {
        fetch(termsAndConditions)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown(prev => {
                    return {...prev, termsAndConditions: text}
                });
            });
        fetch(refundPolicy)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown(prev => ({...prev, refundPolicy: text}));
            });
        fetch(privacyPolicy)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown(prev => ({...prev, privacyPolicy: text}));
            });
        fetch(useOfPersonalInformation)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown(prev => ({...prev, useOfPersonalInformation: text}));
            });
        fetch(contactUs)
            .then((response) => response.text())
            .then((text) => {
                setMarkdown(prev => ({...prev, contactUs: text}));
            });
    }, []);

    return markdown
}