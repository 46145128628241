import { Formik, Form } from "formik"
import * as yup from "yup"
import { InputControl, SubmitButton } from "formik-chakra-ui"
import { pick, omit } from "lodash"
import { useCollectionClasses } from "../../contexts/CollectionClassesProvider"
import { useUser } from "../../contexts/UserProvider"
import AvatarUpload from "../../components/forms/AvatarUpload"
import { useCollectionClass } from "../../contexts/CollectionClassProvider"

import { usePaths } from "../../contexts/PathsProvider"
import { Button, Flex } from "@chakra-ui/react"
import { getFuego } from "swr-firestore-v9"
import { useOutletContext } from "react-router-dom"

export const MembersBasicInfoForm = ({ onClose }) => {
    const { users } = useCollectionClasses()
    const collection = useCollectionClass()
    const { user, updateUser } = useUser()
    const paths = usePaths()
    const fuego = getFuego()
    const {
        onDocUpdated
    } = useOutletContext()

    const schema = yup.object().shape(pick(users.schema,
        [ "photoUrl", "name", "market_name" ],))
    const initialValues = schema.cast(omit(user, fuego.protectedFields))

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true)
        let data = schema.cast(values)
        let valuesWithoutImages = omit(data, collection.imageFields)
        let id = user?.id
        try {
            console.log(valuesWithoutImages)
            await updateUser({ ...valuesWithoutImages, updatedAt: new Date() })
            await onDocUpdated({ ...data, id }, updateUser)
            setSubmitting(false)
            onClose()
        } catch (err) {
            console.log(err)
            setSubmitting(false)
        }
    }


    return (
        <Formik
            initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
            {({ values }) => (
                <Form>
                    <AvatarUpload
                        mb={3}
                        field={"photoUrl"}
                        name={values.name}/>
                    <InputControl
                        mb={2}
                        name={"name"}
                        label={"Name"}
                    />
                    <InputControl
                        mb={2}
                        name={"market_name"}
                        label={"Market Name"} />
                    <Flex justify={"flex-end"} mt={8}>
                        <Button size="md" colorScheme="gray" mr={2} onClick={onClose}>Cancel</Button>
                        <SubmitButton colorScheme={"primary"}>{paths.isNew ? "Save" : "Update"}</SubmitButton>
                    </Flex>
                </Form>
            )}
        </Formik>
    )
}