import { mode } from "@chakra-ui/theme-tools"

export const HeadingGroup = {
    baseStyle: props => ({
        color: mode('gray.600', 'gray.400')(props),
    }),
    variants: {
        dreammore: {
            // color: "white"
        }
    }

}

export default HeadingGroup
