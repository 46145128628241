import { Heading, Stack, Text, useStyleConfig } from '@chakra-ui/react'
import * as React from 'react'

export const HeadingGroup = (props) => {
    const { title, description, variant, ...stackProps } = props
    const styleProps = variant ? { variant } : {}
    const styles = useStyleConfig("HeadingGroup", styleProps)
    // const useGray600 = useColorModeValue('gray.600', 'gray.400')
    // const color = ui.darkBg ? "white" : useGray600
    return (
        <Stack spacing="1" {...stackProps} __css={styles} px={1}>
            <Heading size="md" fontWeight="semibold">
                {title}
            </Heading>
            <Text fontSize={"sm"}>{description}</Text>
        </Stack>
    )
}
