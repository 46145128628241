import * as React from "react"
import { getFuego } from "swr-firestore-v9"
import { chain, isArray, omit } from "lodash"
import { format } from "date-fns";

export const useFormImages = collection => {

    const fuego = getFuego()

    let [ previews, setPreviews ] = React.useState(collection?.getImagePreviews())

    React.useEffect(() => setPreviews(collection?.getImagePreviews()), [ collection ])

    const setPreviewField = field => value => setPreviews(prev => ({ ...prev, [field]: value }))

    const uploadImage = async (data, collection, id) => {
        const { field, _i, dataUrl } = data
        if (!dataUrl) return data
        let date = format(new Date(), "yyyyMMddHHmm")
        let bucket = `img/${collection}/${id}/${field}/${_i || "0"}/${date}`
        let src;
        try {
            src = await fuego.cloud.uploadDataUrl(dataUrl, bucket, true)
        } catch (err) {
            console.log(err)
            return
        }
        return { ...data, src, bucket }
    }

    const handleImageUploads = async (id, previews) => {
        let results = [];
        const tmpFields = [ "_i", "field", "dataUrl" ]
        // console.log("previews", previews)

        try {
            let tasks = chain(previews)
                .map((preview, field) => ({ field, preview }))
                // .filter(({ preview }) => {
                //     if (isArray(preview)) return preview.some(p => p.dataUrl)
                //     return preview.dataUrl
                // })
                .map(({ preview, field }) => {
                    if (isArray(preview)) {
                        return chain(preview)
                            .map((v, _i) => ({ ...v, field, _i }))
                            .value()
                    }
                    return { ...preview, field }
                })
                .flatten()
                .value()

            console.log("tasks", tasks)

            results = await Promise.all(
                tasks.map(task => uploadImage(task, collection.name, id))
            )

            console.log("Promise.all", results)

            // let imageObjects = Object.entries(previews)
            //     .map(([ field, dataUrl ]) => ({ field, dataUrl }))
            // let tasks = chain(imageObjects)
            //     .filter(x => !!x.dataUrl)
            //     .map(x => uploadImage(x.dataUrl, collection.name, id, x.field, x._i))
            //     .value()
            // results = await Promise.all(tasks)
        } catch (err) {
            console.log(err)
            return
        }

        return chain(results)
            .reduce((obj, res) => {
                let { field, _i } = res
                let update = omit(res, tmpFields)
                if (isArray(previews[field])) {
                    if (!obj[field]) obj[field] = [update]
                    else obj[field][_i] = update
                } else obj[field] = update
                return obj
            }, {})
            .value()
        // return reduce(results, (obj, urls) => {
        //     obj = { ...obj, ...urls }
        //     return obj
        // }, {})
    }


    return { previews, setPreviews, setPreviewField, handleImageUploads,
        uploadProps: (field) => ({ preview: previews[field], setPreview: setPreviewField(field)}) }

}

export default useFormImages
