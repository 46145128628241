import { Flex, Stack, Text, Box, useColorModeValue, Divider } from '@chakra-ui/react'
import * as React from 'react'

export const PricingPlanPromoItem = ({ promo }) => {

    // const useGray600 = useColorModeValue('gray.600', 'gray.300')
    const useGreen600 = useColorModeValue("green.600", "green.300")

    const { cashbackStr, gift1Str, gift2Str } = promo
    const extraPromos = [ cashbackStr, gift1Str, gift2Str ]

    if (!extraPromos.some(extra => !!extra)) return null

    return (
        <Box key={promo.id} justify={"flex-start"}>
            <Flex justify="flex-start">
                    <Stack direction="row" spacing="5">
                        {/*<AspectRatio ratio={1} width="92px">*/}
                        {/*  <Image src={product.imageUrl} alt={plan.name} borderRadius="md" />*/}
                        {/*</AspectRatio>*/}
                        <Stack spacing="3">
                            <Stack spacing="1">
                                <Text color={useGreen600} fontWeight="semibold">{promo.name}</Text>
                                {extraPromos.map(extra => {
                                    if (!extra) return null
                                    return <Text key={extra} color={useGreen600}>{extra}</Text>})}
                            </Stack>
                        </Stack>
                    </Stack>
                    <div/>
                    {/*<Text fontWeight="medium">{numberWithCommas(plan.price)}</Text>*/}
                </Flex>
            <Divider mt={2}/>
        </Box>

    )
}

export default PricingPlanPromoItem
