export const Loading = {
    baseStyle: {
        color: "var(--chakra-colors-brand-400)"
    },
    variants: {
        dreammore: {
            color: "white"
        }
    }
}

export default Loading
