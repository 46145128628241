import moment from "moment"

export const now = () => moment().utc().valueOf()

export const topOfHour = () => moment().minute(0).second(0).utc().valueOf()

export const halfPast = () => moment().minute(30).second(0).utc().valueOf()

export const timestamp = {
    now,
    topOfHour,
    halfPast
}

export default timestamp