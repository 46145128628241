import { extendTheme } from "@chakra-ui/react"
import { createCustomTheme, getBaseTheme } from "../themes"
// import dreammoreLogoWhite from "../assets/logo-full-white.png"
// import dreamLogoSubtleBlue from "../assets/logo-full-subtleBlue-600.png"
// import dreammoreLogoBlack from "../assets/logo-full-black.png"
// import dreammoreLogoCoffee from "../assets/logo-full-coffee.png"

import { LogoDark,  } from "../components/logo/logo"


//
// import { some } from "lodash"



export const getUI = (site="dreammore") => {
    // const { location: { host } } = window

    // const devSites = ["localhost:3000", "192.168.1.5:3000"]
    // let sites = {
    //     dreammore: ["dream-more.kr", "dreammore-payments.web.app"],
    //     dreamupup: ["dream-upup.kr"]
    // }
    //
    // const useDevSitesFor = useForDev
    //
    // if (useDevSitesFor) devSites.forEach(dev => sites[useDevSitesFor].push(dev))

    let theme
    // some(sites, (sites, ui) => {
    //     return sites.some(s => {
    //         if (host.endsWith(s)) site = ui
    //         return site
    //     })
    // })

    theme = getBaseTheme()
    // console.log("baseTheme", theme)
    // console.log("site", site)

    const uis = {
        admin: {
            config: {
                initialColorMode: 'light',
                useSystemColorMode: false
            },
            variant: "admin",
            colors: {
                ...theme.colors,
                brand: { ...theme.colors["gray"] },
                primary: { ...theme.colors["blue"] },
                secondary: { ...theme.colors["subtleBlue"] },
                bg: { ...theme.colors["gray"] }
            },
            accentColor: ['brand.500', 'brand.300'],
            mutedColor: ['whiteAlpha.900', 'whiteAlpha.800'],
            activeColor: ['white', 'brand.500'],
            logo: <LogoDark />,
        },
        dreammore: {
            config: {
                initialColorMode: 'light',
                useSystemColorMode: false
            },
            variant: "dreammore",
            colors: {
                ...theme.colors,
                brand: { ...theme.colors["berry"] },
                primary: { ...theme.colors["berry"] },
                secondary: { ...theme.colors["brown"] },
                bg: { ...theme.colors["brown"] }
            },
            accentColor: ['primary.500', 'primary.300'],
            // mutedColor: ['whiteAlpha.900', 'whiteAlpha.800'],
            mutedColor: ['gray.500', 'gray.200'],
            // activeColor: ['white', 'primary.500'],
            activeColor: ['gray.700', 'white'],
            logo: <LogoDark />,
            invertedLogo: null,
            logoSubName: "Dream More"

        },
        // dreamupup: {
        //     variant: "dreamupup",
        //     config: {
        //         initialColorMode: 'dark',
        //         useSystemColorMode: false
        //     },
        //     colors: {
        //         ...theme.colors,
        //         brand: { ...theme.colors["roseWater"] },
        //         primary: { ...theme.colors["roseWater"] },
        //         secondary: { ...theme.colors["coffeePot"] },
        //         tertiary: {...theme.colors["dustyRose"]},
        //         bg: { ...theme.colors["coffeePot"] }
        //     },
        //     accentColor: ['brand.500', 'brand.300'],
        //     mutedColor: ['gray.600', 'whiteAlpha.800'],
        //     activeColor: ['white', 'black'],
        //     logo: dreammoreLogoCoffee,
        //     invertedLogo: null,
        //     logoSubName: "Dream More"
        //
        // }
    }

    theme = extendTheme({ ...theme }, { colors: uis[site].colors })

    // console.log("colors", uis[site].colors)
    // console.log("preui", theme)


    theme = createCustomTheme(theme)
    // console.log("customTheme", theme)

    return { ui: uis[site], theme }

}
