// import { mode } from "@chakra-ui/theme-tools"

export const SignupPage = {
    baseStyle: () => ({
        color: "gray.700",
        // background: mode('white', 'gray.700')(props),
    }),
    variants: {
        dreammore: {
            color: "gray.700"
        },
        "dreammore-bg": {
            background: "bg2.50",
            color: "gray.700"
        },
        dreamupup: {},
        "dreamupup-bg": {}
    }

}
