import * as React from "react"
import {
    signInWithEmailAndPassword, signOut,
    onAuthStateChanged, sendSignInLinkToEmail,
    sendPasswordResetEmail, verifyPasswordResetCode,
    confirmPasswordReset
} from "firebase/auth"
import { getFuego } from "swr-firestore-v9"

let AuthContext = React.createContext({})

export const reservedClaims = [
    "aud",
    "auth_time",
    "email",
    "email_verified",
    "exp",
    "firebase",
    "iat",
    "iss",
    "sub",
    "user_id"
]

export const AuthProvider = ({ children }) => {

    const fuego = getFuego()

    let [ currentUser, setCurrentUser ] = React.useState(undefined)
    let [ claims, setClaims ] = React.useState(null)

    React.useEffect(() => {
        return onAuthStateChanged(fuego.auth, user => {
            if (user) {
                user.getIdTokenResult().then(token => {
                    setClaims(token.claims)
                    setCurrentUser(user)
                })
            } else setCurrentUser(user)
        })
    }, [ fuego.auth ])

    let signin = (email, password, successCallback, errorCallback) => {
        return signInWithEmailAndPassword(fuego.auth, email, password)
            .then(() => {
                if (successCallback) successCallback()
            })
            .catch(err => {
                let loginError
                switch (err.code) {
                    case "auth/wrong-password":
                        loginError = {
                            title: "",
                            message: "Unknown email/password combination"
                        }
                        break
                    default:
                        loginError = {
                            title: "Aw man..",
                            message: "Something went sadly wrong..."
                        }
                }
                if (errorCallback) errorCallback(loginError)
            })
    }

    let signout = () => {
        return signOut(fuego.auth).then(() => {
            setClaims(null)
            setCurrentUser(null)
        })
    }

    let sendSigninLink = (email, subscriberId) => {
        return sendSignInLinkToEmail(fuego.auth, email, {
            url: `http://${window.location.host}/signin/subscribers/${subscriberId}`,
        }).then(() => {
            window.localStorage.setItem('emailForSignIn', email)
        })
    }

    let sendPasswordReset = (email, onSent, onError) => {
        return sendPasswordResetEmail(fuego.auth, email, {
            url: `http://${window.location.host}/login`
        })
            .then(onSent)
            .catch(err => onError(err))
    }

    let verifyPasswordReset = (actionCode, onVerified, onNotVerified) => {
        return verifyPasswordResetCode(fuego.auth, actionCode)
            .then(email => onVerified(email))
            .catch(err => onNotVerified(err))
    }

    let resetPassword = (actionCode, password, onReset, onError) => {
        return confirmPasswordReset(fuego.auth, actionCode, password)
            .then(resp => onReset(resp))
            .catch(onError)
    }


    let value = {
        currentUser, claims, signin, signout, sendSigninLink,
        sendPasswordReset, verifyPasswordReset, resetPassword
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => React.useContext(AuthContext)

export default AuthProvider
