import { AuthComponents } from "./auth"
import { Card } from "./Card"
import { HeadingGroup } from "./HeadingGroup"
import { Loading } from "./Loading"
import { CardLoading } from "./CardLoading"
import { MembersNavbar } from "./MembersNavbar"
import { PageLayout } from "./PageLayout"
import { Input } from "./Input"
import { MobileNavContent } from "./MobileNavContent"
import { NavTabLink } from "./NavTabLink"
import { GalleryUpload } from "./GalleryUpload"

export const components = {
    ...AuthComponents,
    Card,
    HeadingGroup,
    Loading,
    CardLoading,
    MembersNavbar,
    PageLayout,
    Input,
    MobileNavContent,
    NavTabLink,
    GalleryUpload
}