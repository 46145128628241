import * as React from "react"
import { Formik, Form } from "formik"
import { CardLoading } from "../../components/loading/CardLoading"
import { useConstants } from "../../contexts/ConstantsProvider"
import { useSites } from "../../contexts/SiteProvider"
import { useCollectionClasses } from "../../contexts/CollectionClassesProvider"
import { omit } from "lodash"
import BillingInformation from "../../members/checkout/BillingInformation"
import PricingPlanOrderSummary from "../../members/checkout/PricingPlanOrderSummary"
import { SplitPaymentsForm } from "../../members/checkout/SplitPaymentsForm"
import CheckoutLayout from "../../members/CheckoutLayout"
import { Flex } from "@chakra-ui/react"


export const SplitPaymentCheckoutForm = (props) => {
    const { user, balance, transaction } = props
    const { plan, promotions } = transaction

    const constants = useConstants()
    const sites = useSites()
    const { transactionRequests } = useCollectionClasses()

    const { checkout } = constants.strings.members

    const formRef = React.useRef()

    if (!plan || !user) return <CardLoading size={"lg"}/>

    const schema = transactionRequests.getSchema(constants, sites, user, {
        price: balance.balance,
        transactionId: transaction.id
    })
    const initialValues = schema.cast()
    const hiddenValues = omit(initialValues, [ "BuyerName", "BuyerTel", "BuyerEmail", "Amt"])

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(true)
        let schema = transactionRequests.getSchema(constants, sites, {
                name: values.BuyerName,
                mobile: values.BuyerTel,
                email: values.BuyerEmail
            },
            {
                price: values.Amt,
                transactionId: transaction.id
            })
        let newValues = schema.cast()

        let form = formRef.current
        Object.keys(hiddenValues).forEach(key => {
            form[key].value = newValues[key]
        })

        formRef.current.submit()
    }

    return (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
            {
                props => {
                    return (
                        <Flex as={Form} w={"full"} maxW={"2xl"} ref={formRef} method={"post"}
                              action={constants.innopay.paymentURL}>
                            {/*<PrintProps props={props}/>*/}
                            {Object.keys(hiddenValues).map(key => {
                                return <input key={key} name={key} hidden defaultValue={initialValues[key]}/>
                            })}
                            <CheckoutLayout
                                w={"full"}
                                billingInfo={<BillingInformation
                                    constants={constants}
                                    splitPayments={<SplitPaymentsForm strings={checkout} name={"Amt"}
                                                                      orderTotal={balance.balance}/>}/>}
                                orderSummary={plan && <PricingPlanOrderSummary
                                    balance={balance}
                                    plan={plan}
                                    promos={promotions}
                                    constants={constants}
                                    products={[ plan, ...(promotions || []) ]}
                                />}

                            />
                        </Flex>)
                }
            }
        </Formik>
    )
}