import * as React from "react"
import { useOutletContext, useSearchParams } from "react-router-dom"
import { collection as col, doc } from "firebase/firestore"
import { setDoc, getFuego, useCollection } from "swr-firestore-v9"
import { Form, Formik } from "formik"
import { omit, isEmpty, reduce } from "lodash"

import { CheckoutLayout } from "../../members/CheckoutLayout"
import { PricingPlanOrderSummary } from "../../members/checkout/PricingPlanOrderSummary"
import { BillingInformation } from "../../members/checkout/BillingInformation"
import { SplitPaymentsForm } from "../../members/checkout/SplitPaymentsForm"

import { CardLoading } from "../../components/loading/CardLoading"

import { useConstants } from "../../contexts/ConstantsProvider"
import { useSites } from "../../contexts/SiteProvider"
import { useUser } from "../../contexts/UserProvider"
import { useCollectionClasses } from "../../contexts/CollectionClassesProvider"


export const PricingPlanCheckoutForm = () => {

    const constants = useConstants()
    const sites = useSites()
    const { user } = useUser()
    const { transactionRequests } = useCollectionClasses()
    const fuego = getFuego()
    const context = useOutletContext()
    const [searchParams] = useSearchParams()

    const promoInParams = searchParams.get("promoCode")
    const { checkout } = constants.strings.members

    const [transactionId] = React.useState(doc(col(fuego.db, "transactions")).id)

    const [promos, setPromos] = React.useState([])
    const [promoCode, setPromoCode] = React.useState(promoInParams )
    const [promoCodeError, setPromoCodeError] = React.useState("")


    const formRef = React.useRef()
    const promoCodeRef = React.useRef()

    const { data: promotions, loading: loadingPromotions } = useCollection(promoCode ? "promotions" : null, {
        where: [["code", "==", promoCode]]
    })

    const plan = context.docs.plans.data
    const [orderTotal, setOrderTotal] = React.useState(Math.max(0,
        reduce(promos, (price, promo) => price - promo.discount, (plan?.price || 0))))

    React.useEffect(() => {
        // console.log(promoCode, "promotions", promotions, )
        if (!plan) return
        let planPromotions = (promotions || []).filter(promo => promo.plan_id === plan.id)
        // console.log("planPromotions", planPromotions)
        setPromos(isEmpty(planPromotions) ? [] : [planPromotions[0]])

    }, [promotions, plan, promoCode])

    React.useEffect(() => {
        if (!plan) return
        setOrderTotal(Math.max(0,
            reduce(promos, (price, promo) => price - promo.discount, plan.price)))
    }, [promos, plan])


    React.useEffect(() => {
        // console.log(promoCode, isEmpty(promos), checkout.promoCodeNotFound)
        if (promoCode && !loadingPromotions && isEmpty(promos))
            setPromoCodeError(checkout.promoCodeNotFound)
        else setPromoCodeError("")

    }, [promoCode, loadingPromotions, promos, checkout.promoCodeNotFound])


    // console.log("context", context)


    if (!plan || !user) return <CardLoading size={"lg"} />

    const schema = transactionRequests.getSchema(constants, sites, user, {
        price: orderTotal,
        transactionId })
    const initialValues = schema.cast()
    const hiddenValues = omit(initialValues, [ "BuyerName", "BuyerTel", "BuyerEmail", "Amt" ])
    // console.log("initVal", initialValues)

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(true)
        let schema = transactionRequests.getSchema(constants, sites, {
                name: values.BuyerName,
                mobile: values.BuyerTel,
                email: values.BuyerEmail
            },
            {
                price: values.Amt,
                transactionId
            })
        let newValues = schema.cast()

        let form = formRef.current
        Object.keys(hiddenValues).forEach(key => {
            // console.log(key, newValues[key])
            form[key].value = newValues[key]
        })

        let now = new Date()
        let update = {
            user_id: user.id,
            user_name: user.name,
            cash_payment_amt: 0,
            cash_payment_date: null,
            cash_payment_name: "",
            cash_payment_note: "",
            payment_name: newValues.BuyerName,
            payment_mobile: newValues.BuyerTel,
            payment_email: newValues.BuyerEmail,
            plan: omit(plan, constants.fuego.protectedFields),
            plan_id: plan.id,
            plan_name: plan.name,
            promotions: promos.map(promo => omit(promo, constants.fuego.protectedFields)),
            promotion_ids: promos.map(promo => promo.id),
            status: "PROCESSING", // oneOf ("PROCESSING", "PARTIAL_PAID", "PAID", "CANCELLED", "ERROR")
            raw_responses: [],
            result_responses: [],
            createdAt: now,
            updatedAt: now,
        }

        let updateUserWithTransaction = async (cb) => {
            await setDoc(`transactions/${transactionId}`, update)
            cb()
        }
        // setTimeout(() => {setSubmitting(false)}, 5000)
        try {
            updateUserWithTransaction(() => formRef.current.submit())
        } catch (err) {
            console.log(err)
            setSubmitting(false)
        }

    }

    return (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
            {
                props => {
                    // console.log("formik", props)


                    return (
                        <Form ref={formRef} method={"post"} action={constants.innopay.paymentURL}>
                            {/*<PrintProps props={props}/>*/}
                            {Object.keys(hiddenValues).map(key => {
                                return <input key={key} name={key} hidden defaultValue={initialValues[key]}/>
                            })}

                            <CheckoutLayout
                                billingInfo={<BillingInformation
                                    constants={constants}
                                    splitPayments={<SplitPaymentsForm key={orderTotal} strings={checkout} name={"Amt"}
                                                                      orderTotal={orderTotal}/>}
                                />}
                                orderSummary={plan && <PricingPlanOrderSummary
                                    onApplyPromotionCode={setPromoCode}
                                    loadingPromotions={promoCode && loadingPromotions}
                                    plan={plan}
                                    promos={promos}
                                    promotionCodeError={promoCodeError}
                                    constants={constants}
                                    products={[ plan, ...(promotions || []) ]}
                                    promoCode={promoCode}
                                    promoError={promoCodeError}
                                    promoCodeRef={promoCodeRef}
                                />}


                            />
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default PricingPlanCheckoutForm
