import * as React from "react"
import { useCollection } from "swr-firestore-v9"
import { useCollectionClass } from "../../contexts/CollectionClassProvider"
import { useOutletContext, Outlet } from "react-router-dom"
import { useSites } from "../../contexts/SiteProvider"
import { isArray } from "lodash"
import { useFuse } from "../../hooks/useFuse"

export const PlansLayer = () => {

    const sites = useSites()
    const context = useOutletContext() || {}
    const collection = useCollectionClass()

    function getWhere(col) {
        let where = col.getColOpts().where || []
        if (!isArray(where)) where = [ where ]
        return [ ...where, [ "site", "==", sites.currentSite ] ]
    }

    // console.log(collection)
    let plans = useCollection(collection.name, {
        ...collection.getColOpts(),
        where: getWhere(collection)
    })

    // console.log(plans)

    const search = useFuse(collection.searchKeys, plans.data)

    const hasMore = () => {}

    const getNextPage = () => {}

    return <Outlet context={{
        ...context,
        cols: {
            ...(context.cols || {}),
            plans: {
                ...plans,
                search,
                hasMore,
                getNextPage
            }
        }
    }}/>
}

export default PlansLayer
