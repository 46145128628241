
import { InputControl } from "formik-chakra-ui"
import { useField } from "formik"

export const PhoneControl = ({ name, label, formik, ...rest}) => {

    let [{ onBlur }, { value }, { setValue }] = useField(name)

    let onPhoneBlur = val => {
        val = val.replace(/[ -]/g, "")
        if (val.length > 9) {
            return [val.slice(0, -8), val.slice(-8, -4), val.slice(-4)].join("-")
        } else if (val.length > 8) {
            return [val.slice(0, -7), val.slice(-7, -4), val.slice(-4)].join("-")
        }
        return val
    }

    const onPhoneFocus = val => val.replace(/[ -]/g, "")

    // console.log(formik)

    return (

        <InputControl
            name={ name }
            label={ label }
            type={"number"}
            { ...rest }
            onBlur={event => {
                const formatted = onPhoneBlur(value);
                setValue(formatted);
                onBlur(event);
            }}
            onFocus={() => {
                const formatted = onPhoneFocus(value)
                setValue(formatted)

            }}
        />
    )
}

export default PhoneControl
