import { isDate } from "date-fns";
import moment from "moment"

function parseDateString(value, originalValue) {
    if (isDate(originalValue)) return originalValue
    if (moment(originalValue).isValid()) return moment(originalValue).toDate()
    if (originalValue.toDate) return originalValue.toDate()
    return new Date()

}

export default parseDateString
