import { mode } from "@chakra-ui/theme-tools"

export const Card = {
    baseStyle: props => ({
        background: mode('white', 'gray.700')(props),
    }),
    variants: {
        dreammore: {},
        "dreammore-bg": {
            // background: "brown.300",
            background: "transparent",
            color: "white"
        },
        dreamupup: {},
        "dreamupup-bg": {}
    }

}

export default Card
