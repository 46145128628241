import { Heading } from "@chakra-ui/react"

export const AuthPageTitle = ({ title, ...rest }) => {

    return <Heading textAlign="center" size="lg" fontWeight="extrabold" mb={2} {...rest}>
        {title}
    </Heading>
}

export default AuthPageTitle
