import * as React from "react"
import { Formik, Form } from "formik"
import * as yup from "yup"
import { InputControl, SubmitButton } from "formik-chakra-ui"
import Card from "../../components/base/Card"
import { useAuth } from "../contexts/AuthProvider"
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Flex, VStack, Button } from "@chakra-ui/react"

import AuthPageTitle from "../components/AuthPageTitle"
import AuthPageDescription from "../components/AuthPageDescription"
import { useConstants } from "../../contexts/ConstantsProvider"
import { Link as NavLink } from "react-router-dom"

const EmailSentSuccess = ({ description, to, buttonMsg }) => (
    <VStack><Alert status={"success"}>
        <AlertIcon/>{description}
    </Alert><Button w="full" as={NavLink} to={to}>{ buttonMsg }</Button></VStack>
)

const EmailSentError = ({ title, description, to, buttonMsg }) => (
    <VStack><Alert status={"error"}>
        <AlertIcon/>
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
    </Alert><Button w="full" as={NavLink} to={to}>{ buttonMsg }</Button></VStack>
)




export const ForgotPasswordPage = () => {

    const auth = useAuth()
    const constants = useConstants()
    const strings = constants.strings.auth.forgotPasswordPage

    const schema = yup.object().shape({
        email: yup.string()
            .email()
            .required(constants.strings.auth.validation.required)
            .default("")
    })

    let [ sentSuccess, setSentSuccess ] = React.useState("")
    let [ sentError, setSentError ] = React.useState("")

    const onSubmit = (values, { setSubmitting }) => {
        // console.log(values)
        auth.sendPasswordReset(values.email, () => {
                setSentSuccess(values.email)
                setSubmitting(false)
            },
            err => {
                console.log(err)
                setSentError(true)
                setSubmitting(false)
            })
    }

    return (
        <Box className={"forgot-password-form"}>
            {strings.title && <AuthPageTitle title={strings.title}/>}
            {strings.description && <AuthPageDescription description={strings.description}/>}

            <Formik
                initialValues={{ email: "" }}
                onSubmit={onSubmit}
                validationSchema={schema}
            >{() => (
                <Box as={Form}>
                    <Card>
                        {!sentSuccess && !sentError && <><InputControl
                            name="email"
                            label={strings.email}
                            mb={2}
                            isRequired
                            inputProps={{ focusBorderColor: "var(--chakra-colors-brand-500)"}}
                        />
                            <Flex w={"full"} mt={8}>
                                <SubmitButton
                                    type="submit"
                                    size="lg"
                                    fontSize="md"
                                    w={"full"}
                                    colorScheme={"brand"}>
                                    {strings.submitButton}
                                </SubmitButton>
                            </Flex></>}
                        {sentSuccess && <EmailSentSuccess
                            title={strings.sentSuccessTitle}
                            description={constants.strings.formatString(strings.sentSuccessMessage, { email: sentSuccess })}
                            to={constants.auth.loginRoute}
                            buttonMsg={strings.backToLoginButton}
                        />}
                        {sentError && <EmailSentError
                            title={strings.sentErrorTitle}
                            description={strings.sentErrorMessage}
                            to={constants.auth.loginRoute}
                            buttonMsg={strings.backToLoginButton}
                        />}
                    </Card>
                </Box>
            )}
            </Formik>
        </Box>
    )

}

export default ForgotPasswordPage
