import { Box, Flex, Stack, useBreakpointValue, useColorModeValue, Divider } from "@chakra-ui/react"
import * as React from "react"

export const CheckoutLayout = ({ shippingInfo, billingInfo, orderSummary, splitPayments, ...rest }) => (
    <Box
        bgGradient={useColorModeValue(
            'linear(to-l, gray.50 50%, white 50%)',
            'linear(to-l, gray.700 50%, gray.800 50%)',
        )}
        rounded={"md"}
        shadow={"md"}
        { ...rest }
    >
        <Flex
            maxW="8xl"
            mx="auto"
            direction={{
                base: 'column',
                md: 'row',
            }}
            rounded={"md"}
        >
            <Box
                flex="1"
                bg={useColorModeValue('white', 'gray.800')}
                px={{
                    base: '4',
                    md: '8',
                    lg: '12',
                    xl: '20',
                }}
                py={{
                    base: '6',
                    md: '8',
                    lg: '12',
                    xl: '20',
                }}
                rounded={"md"}
            >
                <Stack
                    spacing={{
                        base: '16',
                        lg: '20',
                    }}
                >
                    { orderSummary }
                </Stack>
            </Box>
            <Box
                flex="1"
                maxW={{
                    lg: 'md',
                    xl: '40rem',
                }}
                bg={useBreakpointValue({
                    base: useColorModeValue('white', 'gray.800'),
                    // md: 'inherit',
                })}
                px={{
                    base: '4',
                    md: '8',
                    lg: '12',
                    xl: '20',
                }}
                py={{
                    base: '6',
                    md: '8',
                    lg: '12',
                    xl: '20',
                }}
            >
                <Divider mb={4} d={{base: "block", lg: "none"}}/>
                <Stack
                    spacing={{
                        base: '16',
                        lg: '20',
                    }}
                >
                    { shippingInfo || billingInfo || null }
                </Stack>
            </Box>
        </Flex>
    </Box>
)

export default CheckoutLayout
