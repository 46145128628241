import * as React from "react"
import { Flex, Text, useStyleConfig } from "@chakra-ui/react"
import { useUI } from "../../contexts/UIProvider"

export const AuthPageDescription = ({ description, ...rest }) => {

    const { variant } = useUI()
    const styles = useStyleConfig("AuthPageDescription", { variant })

    return (
        <Flex justify={"space-around"} mb={6} w={"full"} __css={styles} {...rest}>
            <Text as={"span"} align="center" maxW="md" fontWeight="medium">
                { description }
            </Text>

    </Flex>)

}

export default AuthPageDescription

