// import { mode } from "@chakra-ui/theme-tools"

export const Input = {
    // baseStyle: props => ({
    //     _focus: {
    //         borderColor: "pink.500",
    //     }
    //
    //     // background: mode('gray.50', 'inherit')(props),
    // }),
    defaultProps: {
        focusBorderColor: "brand.500",
    },
    variants: {
        dreammore: {
            // focusBorderColor: "brand.500"
        },
        dreamupup: {},
        "dreamupup-bg": {}
    }

}