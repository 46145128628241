import * as React from "react"
import { useSearchParams, Navigate } from "react-router-dom"
import ResetPasswordPage from "./ResetPasswordPage"
import VerifyEmailPage from "./VerifyEmailPage"

import { useConstants } from "../../contexts/ConstantsProvider"

export const AuthActionPage = () => {

    const constants = useConstants()

    let [searchParams,] = useSearchParams()
    let { mode } = Object.fromEntries([...searchParams])

    switch(mode) {
        case 'resetPassword':
            return <ResetPasswordPage />
        // case 'recoverEmail':
        //     // Display email recovery handler and UI.
        //     return <VerifyEmailPage />
        case 'verifyEmail':
            return <VerifyEmailPage />
        default:
            return <Navigate to={constants.auth.loginRoute} />
    }
}

export default AuthActionPage
