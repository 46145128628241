import {
    Button,
    Divider,
    Heading,
    Stack,
    Text,
    Flex,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { Link as NavLink } from "react-router-dom"

import Card from "../../components/base/Card"

import { PaymentStatusTag } from "./PaymentStatusTag"

import { useConstants } from "../../contexts/ConstantsProvider"
import { useUser } from "../../contexts/UserProvider"
import { usePaths } from "../../contexts/PathsProvider"

export const PricingPlanReceiptSummary = ({ plan, status, transactionId }) => {

    const constants = useConstants()
    const { user } = useUser()
    const paths = usePaths()
    const { checkout } = constants.strings.members

    const useGray600 = useColorModeValue('gray.600', 'gray.300')
    const useBrand500 = useColorModeValue("brand.500", "brand.200")
    const useBlackOrWhite = useColorModeValue('black', 'white')


    return (
        <Card w={"full"}>
        <Stack
            spacing={{
                base: '6',
                md: '10',
            }}
        >
            <Heading size="md" color={useBrand500}>{checkout.orderStatusTitle}</Heading>
            <Stack spacing="8">
                <Stack spacing="6">
                    <Flex justify="space-between" key={plan.id}>
                        <Stack direction="row" spacing="5">
                            <Stack spacing="3">
                                <Stack spacing="1">
                                    <Text fontWeight="semibold">{plan.name}</Text>
                                    <Text color={useColorModeValue('gray.600', 'gray.300')}>
                                        { checkout.orderStatusDescription[status] }</Text>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack>
                            <PaymentStatusTag status={status} statusLabel={checkout.orderState[status]} />
                        </Stack>
                    </Flex>
                </Stack>
                {
                    (status === "PAID" || status === "PARTIAL_PAID") && <Stack spacing="6">
                        <Divider/>
                        <Stack direction="row" justify="space-between">
                            <Text color={useGray600} fontWeight={"bold"}>{checkout.orderConfirmationId}</Text>
                            <Text color={useBlackOrWhite}>{ transactionId }</Text>
                        </Stack>
                    </Stack>
                }
            </Stack>
            { status === "PAID" && <Stack spacing="8">
                <Button as={NavLink} to={paths.userProfile(user.id)} colorScheme="brand" size="lg" py="7">
                    {checkout.goToProfile}
                </Button>
            </Stack>}
        </Stack>
        </Card>
    )
}

export default PricingPlanReceiptSummary
