import * as React from "react"
import UserAvatar from "../user-cards/UserAvatar"
import { Button, Flex } from "@chakra-ui/react"
import { useField,
    // useFormikContext
} from "formik"

export const AvatarUpload = props => {

    let { name, field, avatarProps, ...rest } = props
    const [, { value }, { setValue }] = useField(field)
    // const { values } = useFormikContext()

    let imageRef = React.useRef(null)

    let handleImageClick = () => imageRef.current?.click()

    // console.log(`preview ${name}`, value)

    return (
        <Flex align={"center"} {...rest}>
            <UserAvatar
                // name={values.name}
                src={value.dataUrl || value.src}
                bg={"gray.400"}
                avatarProps={avatarProps}
            />
            <Button
                onClick={handleImageClick}
                ml={4}
                size="sm"
                colorScheme={"gray"}
                textTransform={"uppercase"}
            >{value.src ? "Change" : "Upload"}
                <input
                    ref={imageRef}
                    name={name}
                    accept='image/*'
                    type='file'
                    hidden
                    onChange={(e) => {
                        if (!e.target.files[0]) return
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                            if (fileReader.readyState === 2) {
                                setValue({ ...value, dataUrl: fileReader.result });
                            }
                        };
                        fileReader.readAsDataURL(e.target.files[0]);
                    }}
                />
            </Button>
        </Flex>
    )
}

export default AvatarUpload
