import { Box, Avatar, Flex, HStack, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useUser } from "../../contexts/UserProvider"
import { useNavigate } from "react-router-dom"

export const UserProfile = (props) => {
    const user = useUser()
    const navigate = useNavigate()

    // console.log("userprofile", user)
    if (!user || !user.user) return null

    const { name, email, photoUrl } = user.user

    return (
        <Box {...props} className={"user-profile-box"}>
            <HStack
                className={"user-profile-stack"}
                spacing={3}

                // order={{
                //     base: 1,
                //     md: 2,
                // }}
                onClick={() => navigate("/users")}
                cursor={"pointer"}
                px={2}
                py={1}
                rounded={"lg"}
                justify={{
                    base: "flex-start",
                    // md: "flex-end"
                }}
                _hover={{
                    bg: "brand.400"
                }}
                flex="1"
            >
                <Avatar name={name} src={photoUrl?.src} size="sm" bg={"white"} color={"brand.500"}/>
                <Flex
                    direction="column"
                    // display={{
                    //     base: 'flex',
                    //     md: 'none',
                    // }}
                >
                    <Text fontWeight="bold" lineHeight="shorter" color={"gray.800"}>
                        {name}
                    </Text>
                    <Text fontSize="sm" lineHeight="shorter" opacity={0.7} color={"gray.800"}>
                        {email}
                    </Text>
                </Flex>
            </HStack>
        </Box>
    )
}

export default UserProfile
