import { chakra, useColorModeValue } from '@chakra-ui/react'
import { Link as NavLink } from "react-router-dom"

export const Link = ({ to, children, ...props }) => {
    // console.log(props)
    return (
    <chakra.a
        as={NavLink}
        marginStart="1"
        to={ to }
        fontWeight={'bold'}
        color={useColorModeValue('brand.500', 'brand.200')}
        _hover={{
            color: useColorModeValue('brand.600', 'brand.300'),
        }}
        display={{
            base: 'inline',
            sm: 'inline',
        }}
        {...props}
    >{ children }</chakra.a>
)}

export default Link
