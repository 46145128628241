import * as React from "react"
import { useRoutes } from "react-router-dom"
import { keyBy } from "lodash"

import { FuegoProvider } from 'swr-firestore-v9'
import { configureFuego, initializeFuego } from "./firebase/init"

import AuthProvider from "./auth/contexts/AuthProvider"
import UserProvider from "./contexts/UserProvider"
import MenuProvider from "./contexts/MenuProvider"
import CollectionClassesProvider from "./contexts/CollectionClassesProvider"

import getMenuItems from "./hooks/getMenuItems"
import { useConstants } from "./contexts/ConstantsProvider"

import getMemberRoutes from "./routes/site-routes"

import PricingPlans from "./classes/PricingPlans/PricingPlans"
import AccountSettings from "./classes/Users/AccountSettings"
import Transactions from "./classes/Transactions/Transactions"
import TransactionRequests from "./classes/TransactionRequests/TransactionRequests"
// import { Home } from "./classes/MembersHome/Home"
import { MembersSignup } from "./classes/MembersSignup/MembersSignup"

import "./App.css"

const firebase = initializeFuego()

function App() {

    const constants = useConstants()
    const fuego = configureFuego(firebase, constants)

    // const home = new Home()
    const plans = new PricingPlans()
    const accountSettings = new AccountSettings()
    const transactions = new Transactions()
    const transactionRequests = new TransactionRequests()
    const membersSignup = new MembersSignup()

    let collections = [
        // home,
        plans,
        accountSettings,
        transactions,
        transactionRequests,
        membersSignup
    ]

    const collectionsByName = keyBy(collections, "name")

    const menu = getMenuItems(collections)

    let routes = getMemberRoutes(collectionsByName)
    // console.log(routes)

    let element = useRoutes(routes)

    return (
        <FuegoProvider fuego={fuego}>
            <AuthProvider>
                <UserProvider>
                    <CollectionClassesProvider value={collectionsByName}>
                        <MenuProvider value={menu}>
                            {element}
                        </MenuProvider>
                    </CollectionClassesProvider>
                </UserProvider>
            </AuthProvider>
        </FuegoProvider>
    )
}

export default App
