import { useLocation, Navigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthProvider"
import { useConstants } from "../../contexts/ConstantsProvider"
import { isEmpty } from "lodash"

function RequireAuth({ children }) {
    let auth = useAuth()
    let constants = useConstants()
    let location = useLocation()

    let { defaultAuthRoute } = constants.auth
    let { requiredClaims } = constants

    let requiresClaims = !isEmpty(requiredClaims)
    let hasClaims = auth?.claims && requiredClaims.some(claim => auth.claims[claim])
    let doesntHaveClaims = !hasClaims



    if (!auth.currentUser ||
        (auth.currentUser && requiresClaims && doesntHaveClaims)) {
        return <Navigate to={ defaultAuthRoute } state={{ from: location }} replace />;
    }

    return children
}

export default RequireAuth
