import { Avatar, AvatarBadge, Icon, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { GoVerified } from 'react-icons/go'

export const UserAvatar = (props) => {
    const { isVerified, name, src, avatarProps } = props
    const avatarColor = useColorModeValue('white', 'gray.700')
    const iconColor = useColorModeValue('brand.500', 'brand.200')
    return (
        <Avatar size={"xl"} name={name} src={src} {...avatarProps} color={"white"}>
            {isVerified && (
                <AvatarBadge
                    borderWidth="4px"
                    borderColor={avatarColor}
                    insetEnd="3"
                    bottom="3"
                    bg={avatarColor}
                >
                    <Icon as={GoVerified} fontSize="2xl" color={iconColor} />
                </AvatarBadge>
            )}
        </Avatar>
    )
}

export default UserAvatar
