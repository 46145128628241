import { Box, Stack, Button, FormControl, FormHelperText, Center, Text, HStack, VStack, Image } from "@chakra-ui/react"
// import { MembersAccountSettings } from "./MembersAccountSettings"
// import { SocialAccountSettings } from "../../components/userProfile/SocialAccountSettings"
// import { DangerZone } from "../../components/userProfile/DangerZone"
import * as React from "react"
import SignatureCanvas from "react-signature-canvas"
// import AuthStatus from "../../auth/components/AuthStatus"
// import { useUser } from "../../contexts/UserProvider"
import { useConstants } from "../../contexts/ConstantsProvider"
// import { useAuth } from "../../auth/contexts/AuthProvider"
import { useNavigate } from "react-router-dom"
import { useUser } from "../../contexts/UserProvider"
import { useUI } from "../../contexts/UIProvider"
import PageLoading from "../../components/loading/PageLoading"
import { HeadingGroup } from "../../members/userProfile/HeadingGroup"
import { Card } from '../../components/base/Card'
import { TfiReload } from "react-icons/tfi"
import { nanoid } from "nanoid"
import uploadDataUrl from "../../firebase/uploadDataUrl"
import { getDocs, getFuego, updateDoc } from "swr-firestore-v9"
import { sortBy } from "lodash"
import { addDoc, collection } from "firebase/firestore"

export const MembersUserSignatureFormPage = (props) => {

    const constants = useConstants()
    // const auth = useAuth()
    const navigate = useNavigate()
    const { user } = useUser()
    const { variant } = useUI()
    const { db, storage } = getFuego()

    const [previousSignatures, setPreviousSignatures] = React.useState([])


    React.useEffect(() => {
        if (!user || !db) return


        async function fetchPreviousSignatures() {
            let data = await getDocs(`users/${user.id}/signatures`, {}, { parseDates: ["createdAt"]})
            // console.log(data)
            setPreviousSignatures(sortBy(data, ["createdAt"], ["desc"]))
        }

        void fetchPreviousSignatures()

    }, [user, db])

    // console.log("previous signatures", previousSignatures)

    const signatureRef = React.useRef({})
    const [ signatureStarted, setSignatureStarted ] = React.useState(false)
    const [ showSignaturePad, setShowSignaturePad ] = React.useState(false)
    const [ submitting, setSubmitting ] = React.useState(false)

    const strings = constants.strings.members.accountSettings

    React.useEffect(() => {
        const handler = () => setSignatureStarted(false)
        window.addEventListener("resize", handler)
        return () => window.removeEventListener("resize", handler)
    })

    React.useEffect(() => {
        setShowSignaturePad(true)
    }, [])

    const handleClearSignature = ({ setFieldValue }) => {

        if (signatureRef.current.clear) signatureRef.current.clear()
        // setFieldValue("signature", "")
        setSignatureStarted(false)
    }

    const onSignatureSubmit = async ({ setFieldValue,  }) => {
        // console.log("onSignatureSubmit")
        setSubmitting(true)
        const dataURL = signatureRef.current.getCanvas().toDataURL('image/png')
        const randomString = nanoid()
        const src = await uploadDataUrl(dataURL, `signatures/${randomString}.png`, storage)
        await updateDoc(`users/${user.id}`, { signature: src })
        await addDoc(collection(db, `users/${user.id}/signatures`), {
            createdAt: new Date(),
            src
        })

        setSubmitting(false)
        navigate(`/users/${user.id}/edit`)
    }

    React.useEffect(() => {

    }, [])

    if (!user) return <PageLoading size={"lg"} w={"full"}/>

    return <Box maxW="xl" mx="auto">
        <Stack spacing="12">
            <Stack as="section" spacing="4" {...props}>
                <Card shadow={0} variant={`${variant}-bg`}>
                    <HeadingGroup
                        variant={variant}
                        title={strings.basicSettingsTitle}
                        description={strings.basicSettingsDescription}
                    />
                </Card>
                <Stack shouldWrapChildren spacing="4" px={4}>
                    <FormControl>
                        <FormHelperText pb={4}>
                            Write your signature here to change it
                            {/*{constants.strings.members.signupForm.signatureDescription}*/}
                        </FormHelperText>
                        <Box m={0} mx={"auto"} height={"200px"} borderColor={"gray.300"}
                             borderStyle={"solid"} borderWidth={"1px"}
                             position={"relative"}
                             bg={"white"}
                        >
                            <Box position={"absolute"} top={0} left={0} right={0} bottom={0}>

                                <Center h={"full"}>
                                    <Text fontSize={"7xl"} color={"gray.200"}
                                          letterSpacing={"widest"}>{user.name}</Text>
                                </Center>
                            </Box>

                            {showSignaturePad && <SignatureCanvas
                                canvasProps={
                                    {
                                        style: {
                                            width: "100%",
                                            height: "100%",
                                            position: "absolute",
                                            top: 0,
                                            left: 0
                                        }
                                    }
                                }
                                onBegin={() => {
                                    setSignatureStarted(true)
                                }}
                                clearOnResize={false}
                                ref={(ref) => {
                                    signatureRef.current = ref

                                }}/>}
                            <Box position={"absolute"} top={0} right={0} m={2}
                                 color={"gray.400"} fontWeight={"bold"}
                                 onClick={() => handleClearSignature(props)}>
                                <TfiReload size={16}/>
                            </Box>
                        </Box>


                    </FormControl>

                    <HStack justify={"space-between"} mr={2} w={"full"}>
                        {/*<Box/>*/}
                        <Button size="md" onClick={() => navigate(`/users/${user.id}/edit`)}
                                variant="ghost" bg={"gray.50"}>
                            Cancel
                        </Button>
                        <HStack>
                            {/*<Button size="md" onClick={() => {*/}
                            {/*    handleClearSignature(props)*/}
                            {/*    prevStep()*/}
                            {/*}} variant="ghost">*/}
                            {/*    {strings.backButton}*/}
                            {/*</Button>*/}
                            <Button
                                size="md"
                                isLoading={submitting}
                                isDisabled={!signatureStarted}
                                onClick={() => onSignatureSubmit(props)}>
                                Save
                            </Button>
                        </HStack>

                    </HStack>
                    <VStack>
                        <Text pb={4} w={"full"}>
                            Previous Signatures
                            {/*{constants.strings.members.signupForm.signatureDescription}*/}
                        </Text>
                        <VStack maxW={"xl"}>
                            {
                                previousSignatures.map(signature =>
                                    <VStack key={signature.id}>
                                        <Image src={signature.src} alt="signature" bgColor={"white"}/>
                                        <Text pl={1} color={"gray.400"} w={"full"} fontSize={"sm"}>{signature.createdAt.toString()}</Text>
                                    </VStack>)
                            }
                        </VStack>
                    </VStack>
                </Stack>

            </Stack>
            {/*<AuthStatus data={user}/>*/}
            {/*<MembersAccountSettings />*/}
            {/*<SocialAccountSettings />*/}
            {/*<DangerZone />*/}
            {/*<Button*/}
            {/*    colorScheme={"primary"}*/}
            {/*    w={"full"}*/}
            {/*        onClick={() => {*/}
            {/*    auth.signout(() => navigate(constants.auth.loginRoute))*/}
            {/*}}>{ constants.strings.signout }</Button>*/}
        </Stack>
    </Box>
}
