import * as yup from "yup"
import moment from "moment"
import validation from "../../utils/validation"
import dates from "../../utils/dates"
import { omit, pick } from "lodash"
import { getConstants } from "../../config"
import { format } from "date-fns"

const constants = getConstants()
const { strings } = constants

export class MembersSignup {
    constructor() {
        this.name = "signup"
        this.genders = [ "male", "female" ]
        this.schema = {
            // basic info
            name: yup.string()
                .trim()
                .required(strings.validation.required)
                .default(""),
            mobile: validation.koreanMobile.default(""),
            email: yup.string()
                .email()
                .trim()
                .required(strings.validation.required)
                .default(""),

            // create password
            password: validation.password,
            confirm: validation.confirmPassword,

            birthday: yup.date()
                .transform(validation.parseDateString)
                .required(validation.strings.required)
                .default(dates.date.thirtyFiveYearsAgo),

            gender: yup.string()
                .oneOf(this.genders)
                .default("female"),

            agreeTerms: yup.boolean()
                .oneOf([ true ], validation.strings.mustAgree)
                .default(false),
            agreePersonalInfoCollection: yup.boolean()
                .oneOf([ true ], validation.strings.mustAgree)
                .default(false),
            agreeRefundPolicy: yup.boolean()
                .oneOf([ true ], validation.strings.mustAgree)
                .default(false),

            signature: yup.string().default(""),
            confirmSignature: yup.boolean().oneOf([true], validation.strings.mustConfirmSignature).default(false),

        }
    }

    getSchema() {
        return yup.object().shape({
            ...this.schema
        })
    }

    getDefault() {
        return {
            ...this.getSchema().cast(),
            birthday: format(dates.date.thirtyFiveYearsAgo(), "yyyy-MM-dd")
        }
    }

    toUser(values) {
        let now = moment().toDate()
        let modelValues = omit(values,
            [ "password", "confirm" ])
        return {
            ...modelValues,
            createdAt: now,
            updatedAt: now

        }
    }

    toAuthUser(values) {
        return pick(values, ["name", "email", "mobile", "password"])
    }


}