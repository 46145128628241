import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'

export const LoginError = ({ error }) => {

    let message = error.message || "Something went wrong. Sorry."

    return <Alert status='error'>
        <AlertIcon />
        { error.title ? <AlertTitle mr={2}>{ error.title }</AlertTitle> : null }
        <AlertDescription>{ message }</AlertDescription>
    </Alert>
}

export default LoginError
