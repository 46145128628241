import * as React from "react"
import { TabIndicator, TabList, Tabs, useColorModeValue as mode } from "@chakra-ui/react"

export const NavbarTabs = ({ logoLoaded, tabIndex, onTabChange, children }) => {
    const [loadIndicator, setLoadIndicator] = React.useState(logoLoaded)
    React.useEffect(() => setLoadIndicator(logoLoaded), [logoLoaded])


    return (
            <Tabs
                colorScheme="primary"
                variant="unstyled"
                isFitted
                index={tabIndex}
                onChange={onTabChange}>
                <TabList>{ children }</TabList>
                <TabIndicator
                    key={loadIndicator}
                    mt="13px"
                    height={1}
                    borderTopRadius="md"
                    bg={mode('primary.500', 'primary.200')}
                />
            </Tabs>
    )
}

export default NavbarTabs
