import * as React from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "../auth/contexts/AuthProvider"
// import { usePaths } from "../contexts/PathsProvider"

export const GoToEditUserPage = () => {
    let { currentUser } = useAuth()
    // let paths = usePaths()

    return <Navigate to={`/users/${currentUser.uid}/view`} />
}

export default GoToEditUserPage
