export const colors = {
    bg2: {
        50: "#F5F2F0",
        100: "#E2DAD5",
        200: "#CFC3B9",
        300: "#BDAB9E",
        400: "#AA9483",
        500: "#987C67",
        600: "#796353",
        700: "#5B4A3E",
        800: "#3D3229",
        900: "#1E1915"
    },
    subtleBlue: {
        50: "#ecefff",
        100: "#cbceeb",
        200: "#a9aed6",
        300: "#888ec5",
        400: "#666db3",
        500: "#4d5499",
        600: "#3c4178",
        700: "#2a2f57",
        800: "#181c37",
        900: "#080819"
    },
    lipstick:
        {
            50: '#ffeaed',
            100: '#eccacd',
            200: '#d8a9ad',
            300: '#c5878d',
            400: '#b3666d', // main
            500: '#994c53',
            600: '#783a41',
            700: '#57292d',
            800: '#36171b',
            900: '#1a0306',
        },
    coffeePot:
        {
            50: '#feeeee',
            100: '#e1d4d4',
            200: '#c6baba',
            300: '#ad9e9e', // this
            400: '#958282',
            500: '#7c6969',
            600: '#615252',
            700: '#463a3a',
            800: '#2d2222',
            900: '#170808',
        },
    roseWater:
        {
            50: '#ffe9ec',
            100: '#edc4c8',
            200: '#df9ea4',
            300: '#d2787f',
            400: '#c5525b',
            500: '#ad3a41',
            600: '#872d32',
            700: '#602024',
            800: '#3b1215',
            900: '#190405',
        },
    dustyRose:
        {
            50: '#feeceb',
            100: '#eaccc8',
            200: '#d9aaa4',
            300: '#ca8880',
            400: '#bb675c',
            500: '#a34d43',
            600: '#7f3d34',
            700: '#5a2b25',
            800: '#371915',
            900: '#170604',
        },
    greeen:
        {
            50: '#e9f9e7',
            100: '#cbe7c9',
            200: '#acd6a8',
            300: '#8cc487',
            400: '#6db366',
            500: '#53994c',
            600: '#40773a',
            700: '#2c5528',
            800: '#193416',
            900: '#011300',
        },
    brown:
        {
            50: '#ffeeeb',
            100: '#e7d2cc',
            200: '#d1b6ad',  // main
            300: '#be998d',
            400: '#ab7d6d',
            500: '#926354',
            600: '#724c41',
            700: '#52372e',
            800: '#33201a',
            900: '#170802',
        },
    // berry:
    //     {
    //         50: '#ffe9eb',
    //         100: '#efc5c9',
    //         200: '#dfa0a7', // main
    //         300: '#d17b84',
    //         400: '#c35562',
    //         500: '#aa3c48',
    //         600: '#852e37',
    //         700: '#5f2028',
    //         800: '#3b1217',
    //         900: '#1a0307',
    //     },
    berry:
        {
            50: "white",
            100: '#ffe9eb',
            200: '#efc5c9',
            300: '#dfa0a7', // main
            400: '#d17b84',
            500: '#c35562',
            600: '#aa3c48',
            700: '#852e37',
            800: '#5f2028',
            900: '#3b1217',
            // 900: '#1a0307',
        },
    seashell:
        {
            50: '#faeeeb', // offwhite
            100: '#eacdc6',
            200: '#ddab9f',
            300: '#d28877',
            400: '#c86650',
            500: '#af4d36',
            600: '#883c2b',
            700: '#612b1f',
            800: '#391a13',
            900: '#140805',
        },
}

export default colors
