import Loading from "./Loading";
import Card from "../base/Card";
import * as React from "react";

export const CardLoading = props => {

    let { size = "sm", ...rest } = props

    let sizes = {
        sm: "150px",
        md: "300px",
        lg: "450px"
    }

    return (
        <Card {...rest}>
            <Loading h={ sizes[size] }
                     spinnerProps={{
                        // color: "var(--chakra-colors-gray-400)"
            }
            }/>
        </Card>
    )
}

export default CardLoading
