import { mode } from "@chakra-ui/theme-tools"

export const AuthLayout = {
    baseStyle: props => ({
        // color: "gray.700",
        background: mode('gray.50', 'inherit')(props),
    }),
    variants: {
        admin: {
            background: "primary.200",
            bgGradient: "linear(to-b, primary.300, primary.200)"
        },
        dreammore: {
            background: "bg2.50",
            color: "gray.800",
            // bgGradient: "linear(to-b, bg.300, bg.200)"
        },
        dreamupup: {},
        "dreamupup-bg": {}
    }

}
