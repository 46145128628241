import { Link, Tab, useMultiStyleConfig } from '@chakra-ui/react'
import * as React from 'react'
import { useUI } from "../../contexts/UIProvider"

export const NavTabLink = (props) => {

    // let selectedColor = props.darkBg ? ["whiteAlpha.600", "whiteAlpha.800"] : ["brand.600", "brand.200"]
    // let color = props.darkBg ? "white" : "inherit"

    const { variant } = useUI()
    const styles = useMultiStyleConfig("NavTabLink", { variant })
    // console.log(styles.tab, styles.link)
    return (
        <Tab
            // w={"full"}
            className={"nav-tab-link-tab"}
            // _selected={{
            //     color: mode(...selectedColor),
            // }}
            _focus={{
                shadow: 'none',
            }}
            justifyContent="flex-start"
            px={{
                base: 4,
                md: 6,
            }}
            py={3}
            {...styles.tab}
        >
            <Link
                className={"nav-tab-link-link"}
                as="div"
                display="block"
                fontWeight="medium"
                fontSize={{ base: "xl", md: "md"}}
                lineHeight={{ base: "1.5rem", md: "1.25rem" }}
                color="inherit"
                {...styles.link}
                // _hover={{
                //     color: mode(...selectedColor),
                // }}
                // _activeLink={{
                //     color: mode(...selectedColor),
                // }}
                {...props}
            />

        </Tab>
    )
}

export default NavTabLink