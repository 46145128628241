import { Box, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { useStep } from './useStep'

export const StepContent = (props) => {
    const { isLastStep } = useStep()
    return (
        <Box
            className={"step-data"}
            color={useColorModeValue('gray.600', 'gray.400')}
            borderStartWidth={isLastStep? { base: 0, sm: '1px'} : '0'}
            ms={{
                base: "0",
                sm: "4"
            }}
            mt="2"
            ps={"2"}
            // ps="8"
            pb="3"
            fontSize="sm"
            {...props}
        />
    )
}