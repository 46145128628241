import { useEffect, useState, useRef } from "react";
import Fuse from "fuse.js";
import { debounce, isEmpty } from "lodash";

export const useFuse = (searchKeys, data) => {

    let fuse = useRef(null);
    let [ searchData, setSearchData ] = useState(data);
    let [ searchTerm, setSearchTerm ] = useState("");
    let [ searchResults, setSearchResults ] = useState([]);

    useEffect(() => setSearchData(data || []), [data])

    useEffect(() => {
            if (isEmpty(searchKeys) || isEmpty(searchData)) return;
            fuse.current = new Fuse(searchData, {
                keys: searchKeys,
                useExtendedSearch: true
            })
        },
        [ searchData, searchKeys ]);

    let refineSearch = debounce((searchTerm) => {
        // console.log("refine", searchTerm, fuse.current)
        if (!fuse.current) return;
        if (!searchTerm) {
            setSearchTerm(searchTerm)
            setSearchResults([]);
            return;
        }

        let results = fuse.current.search(`'${searchTerm}`)
        setSearchTerm(searchTerm)
        setSearchResults(results)

    }, 300, { trailing: true })

    return { fuse, searchTerm, searchResults, refineSearch, setSearchData }
}