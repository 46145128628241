import * as React from "react"
import * as yup from "yup"
// import LocalizedStrings from "react-localization"
import { useSearchParams } from "react-router-dom"
import { Box, Flex } from "@chakra-ui/react"
import { SubmitButton } from "formik-chakra-ui"
import { Formik, Form } from "formik"
import validation from "../../utils/validation"

import Card from "../../components/base/Card"
import PasswordConfirmControl from "../../components/forms/PasswordConfirmControl"
import AuthErrorCard from "../components/AuthErrorCard"
import AuthPageTitle from "../components/AuthPageTitle"
import AuthPageDescription from "../components/AuthPageDescription"

import { useAuth } from "../contexts/AuthProvider"
import { useConstants } from "../../contexts/ConstantsProvider"


export const ResetPasswordPage = () => {

    // let strings = new LocalizedStrings({
    //     en: {
    //         description: "Create a new password",
    //
    //         passwordLabel: "Password",
    //         confirmLabel: "Confirm Password",
    //         tooShort: "Password must be at least {min} characters",
    //         passwordsDontMatch: "Passwords don't match",
    //         required: "Required",
    //         setPasswordButton: "Set Password",
    //         codeInvalid: "This code is no longer valid. Please try again.",
    //         resetErrorMessage: "There was an error reseting your password. Please try again.",
    //         backToLoginBtn: "Back to Login",
    //         somethingIsWrong: "Something is wrong. Try logging in instead."
    //     }
    // })

    let auth = useAuth()
    let constants = useConstants()
    console.log(constants.auth.strings)

    const strings = constants.auth.strings.resetPasswordPage

    let [ codeVerified, setCodeVerified ] = React.useState(false)
    let [ verificationError, setVerificationError ] = React.useState("")
    let [ resetError, setResetError ] = React.useState("")

    let [ searchParams, ] = useSearchParams()
    let { oobCode: actionCode, continueUrl } = Object.fromEntries([ ...searchParams ])

    let schema = yup.object().shape({
        password: validation.password,
        confirm: validation.confirmPassword
    })

    let initialValues = schema.cast()

    let onSubmit = (values, { setSubmitting }) => {
        auth.resetPassword(actionCode, values.password, () => {
            window.location.href = continueUrl
        }, err => {
            console.log(err)
            setResetError(strings.resetErrorMessage)
            setSubmitting(false)
        })
    }

    React.useEffect(() => {
        if (actionCode) {
            auth.verifyPasswordReset(actionCode,
                () => setCodeVerified(true),
                () => {
                    setVerificationError(strings.codeInvalid)
                })
        } else {
            setVerificationError(strings.somethingIsWrong)
        }

        // else navigate("/login")

    }, [ auth, actionCode, strings.codeInvalid, strings.somethingIsWrong ])

    return <Box className={"reset-password-form"}>
        {/*<Logo/>*/}

        {verificationError && <AuthErrorCard
            errorMessage={verificationError}
            buttonLabel={strings.backToLoginBtn}
            loginRoute={constants.auth.loginRoute}
        />}

        {resetError && <AuthErrorCard
            errorMessage={resetError}
            buttonLabel={strings.backToLoginBtn}
            loginRoute={constants.auth.loginRoute}
        />}

        {codeVerified && !verificationError && !resetError && <>
            {strings.title && <AuthPageTitle title={strings.title}/>}
            {strings.description && <AuthPageDescription description={strings.description}/>}
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={schema}
            >{props => (
                <Box as={Form}>
                    <Card>
                        <PasswordConfirmControl
                            passwordName={"password"}
                            passwordLabel={strings.passwordLabel}
                            confirmName={"confirm"}
                            confirmLabel={strings.confirmLabel}
                            formik={props}
                        />
                        <Flex w={"full"} mt={8}>
                            <SubmitButton type="submit" size="lg" fontSize="md" w={"full"} colorScheme={"primary"}>
                                {strings.setPasswordButton}
                            </SubmitButton>
                        </Flex>
                    </Card>
                </Box>)}
            </Formik>
        </>}
    </Box>
}


export default ResetPasswordPage
