import {
    Heading,
    Stack,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { InputControl, SubmitButton } from "formik-chakra-ui"
import PhoneControl from "../../components/forms/PhoneControl"
import OrderSupport from "../../components-pro/checkout/OrderSupport"

export const BillingInformation = ({ constants, support, splitPayments }) => {
    
    const { checkout } = constants.strings.members
    const { billing } = checkout

    const useGray700 = useColorModeValue('gray.700', 'gray.200')
    const useBrand500 = useColorModeValue('brand.500', 'brand.200')
    
    return (
        <Stack
            spacing={{
                base: '6',
                md: '10',
            }}
        >
            <Heading size="md" color={useBrand500}>{ billing.title }</Heading>
            <Stack
                spacing={{
                    base: '6',
                    md: '8',
                }}
            >
                <InputControl
                    name={"BuyerName"}
                    label={ billing.name }
                    labelProps={{ color: useGray700}}
                    inputProps={{
                        bg: "white",
                        focusBorderColor: useBrand500}}
                />
                <PhoneControl
                    name={"BuyerTel"}
                    label={ billing.mobile }
                    labelProps={{ color: useGray700}}
                    inputProps={{
                        bg: "white",
                        focusBorderColor: useBrand500}}
                />
                <InputControl
                    name={"BuyerEmail"}
                    label={ billing.email }
                    labelProps={{ color: useGray700}}
                    inputProps={{
                        bg: "white",
                        focusBorderColor: useBrand500 }}
                />

                {/*<HStack spacing="6">*/}
                {/*    <FormControl id="zip" maxW="32">*/}
                {/*        <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>Zip Code</FormLabel>*/}
                {/*        <Input*/}
                {/*            name="zip"*/}
                {/*            placeholder="Zip Code"*/}
                {/*            focusBorderColor={useColorModeValue('brand.500', 'brand.200')}*/}
                {/*        />*/}
                {/*    </FormControl>*/}
                {/*    <FormControl id="city">*/}
                {/*        <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>City</FormLabel>*/}
                {/*        <Input*/}
                {/*            name="city"*/}
                {/*            placeholder="City"*/}
                {/*            focusBorderColor={useColorModeValue('brand.500', 'brand.200')}*/}
                {/*        />*/}
                {/*    </FormControl>*/}
                {/*</HStack>*/}

            </Stack>
            <Stack spacing="8">
                { splitPayments }
                <SubmitButton colorScheme="brand" size="lg" py="7" loadingText={checkout.placingOrderLoading}>
                    {checkout.placeOrderButton}
                </SubmitButton>
                {support && <OrderSupport/>}
            </Stack>
        </Stack>
    )
}

export default BillingInformation
