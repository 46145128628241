import * as React from "react"
import { useDocument, getFuego } from "swr-firestore-v9"
import { useParams, Outlet, useOutletContext } from "react-router-dom"
import { omit } from "lodash"
import { useCollectionClass } from "../contexts/CollectionClassProvider"

export const DocLayer = ({ idParam }) => {

    let params = useParams()
    let id = idParam ? params[idParam] : params["id"]

    const fuego = getFuego()
    const context = useOutletContext() || {}
    const collection = useCollectionClass() || {}

    let doc = useDocument(
        id ? `${collection.name}/${id}` : null,
        collection.getColOpts()
    )

    let updateDoc = values => doc.update(omit(values, fuego.protectedFields))

    return <Outlet context={{
        ...context,
        docs: {
            ...(context.docs || {}),
            [collection.name]: { ...doc, updateDoc } }
        }
    } />
}

export default DocLayer
