import {
    Box,
    Button,
    Divider,
    Heading,
    HStack,
    FormControl,
    FormHelperText,
    // Icon,
    Input,
    Stack,
    Text,
    Tag,
    useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
// import { SubmitButton } from "formik-chakra-ui"
import PricingPlanItem from "./PricingPlanItem"
import PricingPlanPromoItem from "./PricingPlanPromoItem"
import { numberWithCommas } from "../../utils"
// import OrderSupport from "../../components-pro/checkout/OrderSupport"
import { reduce } from "lodash"

export const PricingPlanOrderSummary = (props) => {

    const {
        balance,
        constants,
        promos,
        plan,
        promoCodeRef,
        promoCode,
        promoError,
        onApplyPromotionCode,
        loadingPromotions
    } = props

    const { checkout } = constants.strings.members
    const products = [ plan ]

    const useWhite = useColorModeValue('white', 'gray.700')
    // const useGray500 = useColorModeValue('gray.500', 'gray.600')
    const useGray600 = useColorModeValue('gray.600', 'gray.300')
    const useGray700 = useColorModeValue('gray.700', 'gray.500')
    const useBrand500 = useColorModeValue("brand.400", "brand.400")
    const useBrand600 = useColorModeValue("brand.500", "brand.300")
    const useBrand700 = useColorModeValue("brand.600", "brand.200")
    const useBlue500 = useColorModeValue("blue.500", "blue.200")
    const useBlackOrWhite = useColorModeValue('black', 'white')

    const payments = balance ? (balance.notifiedPayments || balance.resultPayments) : 0
    const minBalance = balance ? Math.min(
        (balance.notifiedBalance || plan.price),
        (balance.resultBalance || plan.price)
    ) : plan.price
    const total = balance ? minBalance : Math.max(0,
        reduce(promos,
            (price, promo) => price - promo.discount,
            plan.price))

    // console.log(products)

    return (
        <Stack
            spacing={{
                base: '6',
                md: '10',
            }}
        >
            <Heading size="md" color={useBrand600}>{checkout.orderSummary}</Heading>
            <Stack spacing="8">
                <Stack spacing="6">
                    {products.map((product) => (
                        <Box key={product.id || product.name}>
                            <PricingPlanItem plan={product}/>
                            <Divider mt={2}/>
                        </Box>
                    ))}
                    {
                        promos.map(promo => (
                            <Box key={promo.code}>
                                <PricingPlanPromoItem promo={promo}/>
                                {/*<Divider/>*/}
                            </Box>
                        ))
                    }
                </Stack>
                {promoCodeRef && <FormControl>
                    <HStack spacing="6">

                        <Input
                            ref={promoCodeRef}
                            placeholder={checkout.promotionCodePlaceholder}
                            focusBorderColor={useBrand500}
                            bg={useWhite}
                            size="md"
                            onChange={ev => ev.target.value = ev.target.value.toUpperCase()}
                        />


                        <Button
                            isLoading={loadingPromotions}
                            onClick={() => {
                                if (!promoCodeRef.current.value) return
                                onApplyPromotionCode(promoCodeRef.current.value)
                                promoCodeRef.current.value = ""
                            }}
                            size="md"
                            px="8"
                            bg={useBrand600}
                            _hover={{
                                bg: useBrand700,
                            }}
                            _active={{
                                bg: useBrand700,
                            }}
                            color="white"
                        >
                            Apply
                        </Button>
                    </HStack>
                    {promoCode && !promoError && <FormHelperText pl={1}><Tag color={"white"} bg={"gray.600"}
                                                                             size={"sm"}>{promoCode}</Tag></FormHelperText>}
                    {promoCode && promoError &&
                        <FormHelperText pl={1} color={"brand.500"}>{promoError}</FormHelperText>}


                </FormControl>}
                <Stack spacing="6">
                    <Stack spacing="3">
                        <Stack direction="row" justify="space-between">
                            <Text color={useGray600}>{checkout.subtotal}</Text>
                            <Text color={useBlackOrWhite}>{numberWithCommas(plan.price)}</Text>
                        </Stack>
                        {/*<Stack direction="row" justify="space-between">*/}
                        {/*  <Text color={useColorModeValue('gray.600', 'gray.300')}>Shipping cost</Text>*/}
                        {/*  <Text color={useColorModeValue('black', 'white')}>+$24.99</Text>*/}
                        {/*</Stack>*/}
                        {promos.length > 0 && promos.map(promo => <Stack key={promo.code} direction="row"
                                                                         justify="space-between">
                            <Text color={useGray600}>{promo.discountStr}</Text>
                            <Text color={useBrand500}>-{numberWithCommas(promo.discount)}</Text>
                        </Stack>)}
                        {payments && <Stack direction="row" justify="space-between">
                            <Text color={useGray600}>{checkout.partialPaymentLabel}</Text>
                            <Text color={useBlue500}>-{numberWithCommas(payments)}</Text>
                        </Stack>}
                    </Stack>
                    <Divider/>
                    <Stack direction="row" justify="space-between">
                        <Text
                            fontSize="lg"
                            fontWeight="semibold"
                            color={useGray700}
                        >
                            {checkout.orderTotal}
                        </Text>
                        <Text fontSize="xl" fontWeight="semibold" color={useBlackOrWhite}>
                            {numberWithCommas(total)}
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
            {/*<Stack spacing="8">*/}
            {/*    <SubmitButton colorScheme="brand" size="lg" py="7" loadingText={checkout.placingOrderLoading}>*/}
            {/*        {checkout.placeOrderButton}*/}
            {/*    </SubmitButton>*/}
            {/*    {support && <OrderSupport/>}*/}
            {/*</Stack>*/}
        </Stack>
    )
}

export default PricingPlanOrderSummary
