import { ref, uploadString, getDownloadURL } from "firebase/storage"

export const uploadDataUrl = (dataUrl, destination, storage) => {
    return new Promise(async (resolve, reject) => {
        let storageRef = ref(storage, destination);
        try {
            let snapshot = await uploadString(storageRef, dataUrl, "data_url");
            let url = await getDownloadURL(snapshot.ref)
            resolve(url)
        } catch (err) {
            reject(err)
        }
    });
}

export default uploadDataUrl;
