import { mode } from "@chakra-ui/theme-tools"
export const MobileNavContent = {
    baseStyle: props => ({
        bg: mode("white", "gray.800")(props)
    }),
    variants: {
        dreammore: props => ({
            // bg: "brown.300",
            bg: "bg2.50",
            color: "gray.800",
            // color: "white"
        }),
        dreamupup: props => ({
            bg: "primary.100",
            // color: "white"
        })
    }
}

export default MobileNavContent
