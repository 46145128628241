import { mode } from "@chakra-ui/theme-tools"

export const PageLayout = {
    baseStyle: props => ({
        background: mode('red.50', 'inherit')(props),
        minH: "100vh"
    }),
        variants: {
        dreammore: {
            // background: "brown.300",
            bg: "bg2.50",
            // bgGradient: "linear(to-b, brown.300, brown.200)"
        }
    }

}

export default PageLayout
