import dates from "./dates"
import validation from "./validation"
import encoding from "./encoding"

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const utils = {
    numberWithCommas,
    dates,
    validation,
    encoding
}

export default utils


