import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter } from "react-router-dom"
// import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui'
import ConstantsProvider from "./contexts/ConstantsProvider"
import UIProvider from "./contexts/UIProvider"
import ScrollToTop from "./components/ScrollToTop"
import { getConstants } from "./config"
import { getUI } from "./hooks/getUI"

// fonts need to be imported in index.js
import "@fontsource/montserrat"
import "@fontsource/noto-sans-kr"

const config = getConstants()
const { ui, theme } = getUI("dreammore")
// console.log("indexTheme", theme)


ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            {/*<ThemeEditorProvider>*/}
                <UIProvider value={ui}>
                    <ConstantsProvider value={config}>
                        <BrowserRouter>
                            <ScrollToTop>
                                <App/>
                            </ScrollToTop>
                        </BrowserRouter>
                    </ConstantsProvider>
                </UIProvider>
            {/*</ThemeEditorProvider>*/}
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
