import { mode } from "@chakra-ui/theme-tools"

export const NavTabLink = {
    parts: [ "tab", "link" ],
    baseStyle: props => ({
        tab: {
            _selected: { color: mode("brand.600", "brand.200")(props) }
        },
        link: {
            _hover: {
                color: mode("brand.600", "brand.800")(props),
            },
            _activeLink: {
                color: mode("brand.600", "brand.800")(props),
            }
        }

    }),
    variants: {
        dreammore: props => ({
            tab: {
                    // _selected: { color: mode("whiteAlpha.600", "whiteAlpha.800")(props) },
                    // color: "white"

            },
            link: {
                    // _hover: {
                    //     color: mode("whiteAlpha.600", "whiteAlpha.800")(props),
                    // },
                    // _activeLink: {
                    //     color: mode("whiteAlpha.600", "whiteAlpha.800")(props),
                    // }
            }
        })


    }
}
