import * as React from "react"
import { Outlet, useLocation, Navigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthProvider"
import { Box, Center, useStyleConfig, VStack } from "@chakra-ui/react"
// import Logo from "../../components/Logo"
import { useUI } from "../../contexts/UIProvider"
// import { useConstants } from "../../contexts/ConstantsProvider"
import { isUndefined } from "lodash"
import { BeatLoader } from "react-spinners"

export const AuthLayout = (props) => {
    const auth = useAuth()
    const { variant, logo } = useUI()
    const styles = useStyleConfig("AuthLayout", { variant })
    const loaderStyles = useStyleConfig("BeatLoader", { variant })

    const location = useLocation()

    // const { defaultAuthRoute } = constants.auth
    const currentUserHasntLoaded = isUndefined(auth.currentUser)
    const currentUserHasLoaded = !currentUserHasntLoaded
    // console.log("currentUser", auth.currentUser)
    // console.log("location", location)

    if (currentUserHasLoaded && auth.currentUser && !location.pathname.endsWith("signout"))
        return <Navigate to={ "/" } state={{ from: location }} replace />

    return (
        (currentUserHasntLoaded)
            ?
            <Center direction={"column"} h={"100vh"} __css={styles}>
                <Center>
                    <VStack >
                        {/*<Logo />*/}
                        { logo }
                        <BeatLoader
                            {...loaderStyles}
                            // color={"var(--chakra-colors-brand-500)"}
                        />
                    </VStack>
                </Center>
            </Center>
            :
        <Box
            minH="100vh"
            py="12"
            px={{
                base: '4',
                lg: '8',
            }}
            __css={styles}
            {...props}
        >
            <Box maxW="xl" mx="auto">
                {/*<Logo />*/}
                { logo }
                <Outlet />
            </Box>
        </Box>

    )
}

export default AuthLayout
