import moment from "moment"

// TODO
// remove moment

export const now = () => new Date()

export const topOfHour = () => moment().minute(0).second(0).utc().toDate()

export const halfPast = () => moment().minute(30).second(0).utc().toDate()

export const twentyFiveYearsAgo = () => moment().subtract(25, "y").toDate()

export const thirtyFiveYearsAgo = () => moment().subtract(35, "y").toDate()

export const tonightAtMidnight = () => moment()
    .hours(23)
    .minutes(59)
    .seconds(59).toDate()


export const tomorrowAtMidnight = () => moment().add(1, "day")
    .hours(23)
    .minutes(59)
    .seconds(59).toDate()

export const inAWeek = () => moment().add(1, "day")
    .hours(23)
    .minutes(59)
    .seconds(59).toDate()


export const date = {
    now,
    topOfHour,
    halfPast,
    twentyFiveYearsAgo,
    thirtyFiveYearsAgo,
    tomorrowAtMidnight,
    tonightAtMidnight,
    inAWeek
}

export default date
