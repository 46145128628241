import * as React from "react"
import { Box, Tag, useColorModeValue } from "@chakra-ui/react"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { MdOutlineCancel } from "react-icons/md"
import { PulseLoader } from "react-spinners"
import { BiErrorCircle } from "react-icons/bi"

export const PaymentStatusTag = ({ status, statusLabel, ...rest }) => {
    const useGray600 = useColorModeValue('gray.600', 'gray.300')
    const useGreen500 = useColorModeValue("green.500", "green.200")
    const useBlue500 = useColorModeValue("blue.500", "blue.200")
    const useOrange500 = useColorModeValue("orange.500", "orange.200")
    const useRed500 = useColorModeValue("red.500", "red.200")

    const statusColor = (status) => {
        switch(status) {
            case "PAID":
                return useGreen500
            case "PARTIAL_PAID":
                return useBlue500
            case "CANCELED":
            case "CANCELLED":
                return useOrange500
            case "ERROR":
                return useRed500
            case "PROCESSING":
            default:
                return useGray600

        }
    }

    const tagIcon = status => {
        switch(status) {
            case "PAID":
            case "PARTIAL_PAID":
                return <BsFillCheckCircleFill />
            case "CANCELED":
            case "CANCELLED":
                return <MdOutlineCancel />
            case "PROCESSING":
                return <PulseLoader margin={2} size={5} color={"white"}/>
            case "ERROR":
            default:
                return <BiErrorCircle />
        }
    }

    return (
        <Tag color={"white"} bg={statusColor(status)} {...rest}>
            { statusLabel || "UNKNOWN" }{" "}
            <Box as={"span"} ml={2} color={"white"}>
                { tagIcon(status) }
            </Box>
        </Tag>
    )
}