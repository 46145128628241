import { Box, Stack, Button } from "@chakra-ui/react"
import { MembersAccountSettings } from "./MembersAccountSettings"
// import { SocialAccountSettings } from "../../components/userProfile/SocialAccountSettings"
// import { DangerZone } from "../../components/userProfile/DangerZone"
import * as React from "react"
// import AuthStatus from "../../auth/components/AuthStatus"
// import { useUser } from "../../contexts/UserProvider"
import { useConstants } from "../../contexts/ConstantsProvider"
import { useAuth } from "../../auth/contexts/AuthProvider"
import { useNavigate } from "react-router-dom"

export const MembersUserProfilePage = () => {

    const constants = useConstants()
    const auth = useAuth()
    const navigate = useNavigate()

    return <Box maxW="xl" mx="auto">
        <Stack spacing="12">
            {/*<AuthStatus data={user}/>*/}
            <MembersAccountSettings />
            {/*<SocialAccountSettings />*/}
            {/*<DangerZone />*/}
            <Button
                colorScheme={"primary"}
                w={"full"}
                    onClick={() => {
                auth.signout(() => navigate(constants.auth.loginRoute))
            }}>{ constants.strings.signout }</Button>
        </Stack>
    </Box>
}
