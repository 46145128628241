import Collection from "../Collection"
import { ImPriceTags } from "react-icons/im"
// import LocalizedStrings from "react-localization"
import * as yup from "yup"
import validation from "../../utils/validation"
import dates from "../../utils/dates"
import { isEmpty } from "lodash"
import { getConstants } from "../../config"

const config = getConstants()

export class Transactions extends Collection {
    constructor(props) {
        super(props)

        this.name = "transactions"
        this.title = "Transactions"
        this.modelLabel = "Transaction"

        this.path = "/transactions"
        this.section = "Management" // Sidebar section on admin pane

        this.showOnVersions = ["admin", "superadmin"] // "admin", "superadmin", "members"
        this.showOnlyWithClaims = [] // empty is show on all

        this.sidebarIcon = <ImPriceTags/>

        this.searchKeys = []
        this.imageFields = []

        this.schema = {
            user_id: yup.string().default(""),
            user_name: yup.string().default(""),
            payment_name: yup.string().default(""),
            payment_mobile: validation.koreanMobile.transform(val => val.replace(/[ -]/g, "")),
            payment_email: yup.string().email().default(""),
            plan: yup.object().default({}),
            plan_id: yup.string().default(""),
            plan_name: yup.string().default(""),
            promotions: yup.array().of(yup.object()).default([]),
            promotion_ids: yup.array().default([]),
            status: yup.string().oneOf(["PROCESSING", "PAID", "PARTIAL_PAID", "CANCELED", "ERROR"]),
            raw_response: yup.object(),
            raw_responses: yup.array().default([]),
            result_response: yup.object(),
            result_responses: yup.array().default([]),

            createdAt: yup.date()
                .transform(validation.parseDateString)
                .default(dates.date.now),
            updatedAt: yup.date()
                .transform(validation.parseDateString)
                .default(dates.date.now)
        }

        this.parseDates = ["createdAt", "updatedAt"]
    }

    getBalance(tx) {

        const { notifiedKeys, resultKeys, resultCodes } = config.innopay

        const { plan, promotions, raw_responses, result_responses, cash_payment_amt} = tx
        const { price } = isEmpty(plan) ? { price : 1980000 } : plan
        const discounts = (promotions || [])
            .reduce((total, promo) =>
                    (total + Number(promo.discount)),
                0)
        const notifiedPayments = (raw_responses || [])
            .reduce((total, payment) =>
                    (total + Number(payment[notifiedKeys.paid]) - Number(payment[notifiedKeys.cancelled])),
                0)
        const resultPayments = (result_responses || [])
            .reduce((total, payment) => {
                    let resultCode = `${payment[resultKeys.code]}`
                    if (resultCode === resultCodes.cancel) return total - Number(payment[resultKeys.cancel])
                    if (resultCode === resultCodes.paid) return total + Number(payment[resultKeys.paid])
                    return total
                },
                0)

        const initialBalance = price - discounts - cash_payment_amt
        const notifiedBalance = initialBalance - notifiedPayments
        const resultBalance = initialBalance - resultPayments
        return {
            cash_payment_amt,
            price,
            discounts,
            notifiedPayments,
            resultPayments,
            notifiedBalance,
            resultBalance,
            balance: notifiedBalance
        }
        // console.log(result)
        // return result
    }
}

export default Transactions
