
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"
import "firebase/functions"

import { Fuego } from "swr-firestore-v9"
import { getApp } from "firebase/app"
import { getAuth, connectAuthEmulator } from "firebase/auth"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import { enableMultiTabIndexedDbPersistence, connectFirestoreEmulator } from "firebase/firestore"
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

import { uploadDataUrl } from "./uploadDataUrl"
import { uploadImage } from "./uploadImage"


const firebaseConfig = {
    apiKey: "AIzaSyDkOfFbFoHh7nClqB1qoaQ2fo_g3b8uNP0",
    authDomain: "mozzimong-shop.firebaseapp.com",
    projectId: "mozzimong-shop",
    storageBucket: "mozzimong-shop.appspot.com",
    messagingSenderId: "434079219412",
    appId: "1:434079219412:web:9cbecc1e1da8a49d874541",
    measurementId: "G-YCPT9DYY79"
};

const captchaClientKey = "6Lc8QCQeAAAAAC0VE7TFu3tfKC3TuEqGLgCsVtq_"

// let firebaseConfig = {
//     apiKey: "AIzaSyDChZEpPD5DmFI_kdwsYfytyIPn-SV4AuM",
//     authDomain: "supersimpleshop-71b5f.firebaseapp.com",
//     databaseURL: "https://supersimpleshop-71b5f.firebaseio.com",
//     projectId: "supersimpleshop-71b5f",
//     storageBucket: "supersimpleshop-71b5f.appspot.com",
//     messagingSenderId: "241290902224",
//     appId: "1:241290902224:web:30e63eeaa78ca14d957cd5",
//     measurementId: "G-TN213FMKT4"
// };

export const fuego = new Fuego(firebaseConfig)

if (process.env.NODE_ENV !== "test") {
    global.self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN
    console.log("Using APPCHECK DEBUG TOKEN", process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN)
} else if (process.env.NODE_ENV !== 'production' ) {
    // analytics.disable();
    global.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

}

initializeAppCheck(getApp(), {
    provider: new ReCaptchaV3Provider(captchaClientKey),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

export const initializeFuego = () => {
    fuego.auth = getAuth()

    fuego.functions = getFunctions()
    fuego.storage = getStorage()

    if (process.env.NODE_ENV !== 'production') {
        connectAuthEmulator(fuego.auth, "http://localhost:9099")
        connectFunctionsEmulator(fuego.functions, "localhost", 5001)
        connectFirestoreEmulator(fuego.db, "localhost", 8080)
        connectStorageEmulator(fuego.storage, "localhost", 9199)
    } else {
        enableMultiTabIndexedDbPersistence(fuego.db)
    }

    fuego.cloud = {}
    fuego.cloud.uploadDataUrl = (dataUrl, destination, verbose=false) => {
        return uploadDataUrl(dataUrl, destination, fuego.storage, verbose)
    }
    fuego.cloud.uploadImage = (imageFile, destination, verbose=false) => {
        return uploadImage(imageFile, destination, fuego.storage, verbose)
    }

    return fuego

}

export const configureFuego = (fuego, config) => {

    fuego.protectedFields = config.fuego.protectedFields

    fuego.callable = {}
    config.fuego.callables.forEach(funcName => {
        fuego.callable[funcName] = httpsCallable(fuego.functions, funcName)
    })

    return fuego
}
