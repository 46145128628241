import { mode } from "@chakra-ui/theme-tools"

export const MembersNavbar = {
    parts: ["navbar", "hamburger"],
    baseStyle: props => ({
        navbar: {
            bg: mode("white", "gray.800")(props)
        }
    }),
    variants: {
        dreammore: {
            navbar: {
                // bg: "brown.300",
                bg: "bg2.100",
                shadow: "lg",
                shadowColor: "white",
                // color: "white",
                color: "gray.800",
            },
            hamburger: {
                // color: "white"
                color: "gray.800"
            }

        },
        dreamupup: {
            navbar: {
                bg: "primary.100",
                shadow: "lg",
                color: "white"
            },
            hamburger: {
                color: "primary.500"
            }

        }
    }
}

export default MembersNavbar