import { Box, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { useStep } from './useStep'

export const StepConnector = () => {
    const { isCompleted, isActive } = useStep()
    const accentColor = useColorModeValue('white', 'brand.300')
    return (
        <Box
            className={"step-connector"}
            borderStartWidth="1px"
            borderStartColor={isCompleted ? accentColor : 'inherit'}
            height="6"
            mt={isActive ? '0' : '2'}
            mb="2"
            ms="4"
            ps="4"
        />
    )
}