import Collection from "../Collection"
import LocalizedStrings from "react-localization"
import { BsPerson } from "react-icons/bs"
import * as yup from "yup"
import dates from "../../utils/dates"
import validation from "../../utils/validation"
import { getConstants } from "../../config"

const config = getConstants()
const { sites } = config

export class Users extends Collection {
    constructor(props) {
        super(props)

        this.name = "users"
        this.title = "Users"
        this.modelLabel = "User"

        this.path = "/users"
        this.section = "Collections" // Sidebar section on admin pane
        this.sidebarIcon = <BsPerson/>

        this.searchKeys = ["name", "email", "mobile"]

        this.parseDates = [ "createdAt", "updatedAt", "birthday", "subscription_date", "subscription_expiresAt" ]
        this.imageFields = [ "photoUrl" ]

        this._strings = {
            en: {
                labels: {
                    name: "Name",
                    email: "Email",
                    mobile: "Mobile Phone Number",
                    shop_id: "Shop ID",
                }
            }
        }
        this.strings = new LocalizedStrings(this._strings)


        this.schema = {
            // basic info
            name: yup.string()
                .trim()
                .required(validation.strings.required)
                .default(""),
            email: yup.string()
                .trim()
                .email()
                .required(validation.strings.required)
                .default(""),
            mobile: validation.koreanMobile,

            birthday: yup.date()
                .transform(validation.parseDateString)
                .required(validation.strings.required)
                .default(dates.date.thirtyFiveYearsAgo),

            gender: yup.string()
                .oneOf([ "male", "female" ])
                .default("female"),

            // accept terms
            agreeTerms: yup.boolean()
                .oneOf([ true ], validation.strings.mustAgree)
                .default(false),
            agreePersonalInfoCollection: yup.boolean()
                .oneOf([ true ], validation.strings.mustAgree)
                .default(false),

            signature: yup.string().default(""),
            confirmSignature: yup.boolean().oneOf([true], validation.strings.mustConfirmSignature).default(false),

            // references
            shop_id: yup.string()
                .default(""),

            // images
            photoUrl: yup.object().shape({
                src: yup.string().default(""),
                bucket: yup.string().default("")
            }),

            //
            market_name: yup.string()
                .trim()
                .default(""),

            // subscriptions
            subscription_date: yup.date()
                .transform(dates.timestampTransform)
                .nullable()
                .default(null),
            subscription_expiresAt: yup.date()
                .transform(dates.timestampTransform)
                .nullable()
                .default(null),
            subscription_transaction_id: yup.string().default(""),
            plan_id: yup.string().default(""),
            plan_name: yup.string().default(""),

            // admin
            currentSite: yup.string()
                .oneOf(sites.map(site => site.value))
                .default(sites[0].value),

            // meta
            createdAt: yup.date()
                .transform(dates.timestampTransform)
                .default(dates.date.now),

            updatedAt: yup.date()
                .transform(dates.timestampTransform)
                .default(dates.date.now)

        }

        this.sortBy = [
            [ "isHidden", "orderIndex" ],
            [ "desc", "asc" ]
        ]

        this.colOpts = {
            listen: true,
            orderBy: [ "createdAt", "desc" ],
            parseDates: this.parseDates

        }

        this.initialData = []
        this.swrOpts = {
            // fallbackData: this.initialData
        }
    }

    getSignUpSchema() {
        return yup.object().shape({
            ...this.schema,
            password: validation.password,
            confirm: validation.confirmPassword
        })
    }

}


export default Users
