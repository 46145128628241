import * as React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Box, Stack } from '@chakra-ui/react'

import { Card } from '../../components/base/Card'
import LoginError from "../components/LoginError"

import { useAuth } from "../contexts/AuthProvider"
import { useConstants } from "../../contexts/ConstantsProvider"
import * as yup from "yup";
import { Form, Formik } from "formik";
import { InputControl, SubmitButton } from "formik-chakra-ui"
import PasswordControl from "../../components/forms/PasswordControl"

import AuthPageTitle from "../components/AuthPageTitle"
import AuthPageDescription from "../components/AuthPageDescription"

// import { FaFacebook, FaGithub, FaGoogle } from 'react-icons/fa'
// import { DividerWithText } from '../components/base/DividerWithText'
// import { Link } from '../components/base/Link'

export const LoginPage = () => {

    let auth = useAuth()
    const constants = useConstants()

    const strings = constants.strings.auth.loginPage

    let navigate = useNavigate()
    let location = useLocation()
    let from = location.state?.from?.pathname || "/";

    let [ loginError, setLoginError ] = React.useState({})

    let schema = yup.object().shape({
        email: yup.string().email().required(strings.emailRequired).default(""),
        password: yup.string().required(strings.passwordRequired).default("")
    })

    const onSubmit = (values, { setSubmitting }) => {
        auth.signin(values.email, values.password,
            () => navigate(from, { replace: true }),
            err => {
                setLoginError(err)
                setSubmitting(false)
            }
        )
    }

    return (
        <Box>

            { strings.title && <AuthPageTitle title={ strings.title }/> }
            { strings.description && <AuthPageDescription description={ strings.description }/> }
            <Card>
                <Formik
                    initialValues={schema.cast()}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                >{
                    () => (
                        <Box as={Form}>
                            <Stack spacing="6">
                                {loginError.message ? <LoginError error={loginError}/> : null}
                                <InputControl
                                    inputProps={{type: "email"}}
                                    name={"email"}
                                    label={strings.emailLabel}
                                />
                                <PasswordControl
                                    name={"password"}
                                    label={strings.passwordLabel}
                                    forgotLink={constants.auth.forgotRoute}
                                />
                                <SubmitButton size="lg" fontSize="md" colorScheme={"primary"}>
                                    {strings.submitButton}
                                </SubmitButton>
                            </Stack>
                        </Box>
                    )
                }
                </Formik>
            </Card>
        </Box>
    )
}

export default LoginPage

// {/*<Logo*/}
// {/*    mx="auto"*/}
// {/*    h="8"*/}
// {/*    mb={{*/}
// {/*        base: '10',*/}
// {/*        md: '20',*/}
// {/*    }}*/}
// {/*/>*/}
// {/*<Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">*/}
// {/*    <Text as="span">Don&apos;t have an account?</Text>*/}
// {/*    <Link href="#">Start free trial</Link>*/}
// {/*</Text>*/}
// {/*<DividerWithText mt="6">or continue with</DividerWithText>*/}
// {/*<SimpleGrid mt="6" columns={3} spacing="3">*/}
// {/*    <Button color="currentColor" variant="outline">*/}
// {/*        <VisuallyHidden>Login with Facebook</VisuallyHidden>*/}
// {/*        <FaFacebook/>*/}
// {/*    </Button>*/}
// {/*    <Button color="currentColor" variant="outline">*/}
// {/*        <VisuallyHidden>Login with Google</VisuallyHidden>*/}
// {/*        <FaGoogle/>*/}
// {/*    </Button>*/}
// {/*    <Button color="currentColor" variant="outline">*/}
// {/*        <VisuallyHidden>Login with Github</VisuallyHidden>*/}
// {/*        <FaGithub/>*/}
// {/*    </Button>*/}
// {/*</SimpleGrid>*/}