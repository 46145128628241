import * as React from "react"
import { getConstants } from "../config"
import { useUser } from "./UserProvider"


const config = getConstants()

export const SiteContext = React.createContext({
    currentSite: config.sites[0].value,
    options: config.sites,
    setCurrentSite: () => {}
})

export const SiteProvider = ({ children }) => {
    let { user, updateUser } = useUser()

    // React.useEffect(() => { console.log("siteProvider", user) }, [user])

    return <SiteContext.Provider value={{
        currentSite: user?.currentSite || config.sites[0].value,
        options: config.sites,
        setCurrentSite: currentSite => updateUser({ currentSite })
            .catch(err => console.log(err))

    }}>
        { children }
    </SiteContext.Provider>
}

export const useSites = () => React.useContext(SiteContext)

export default SiteProvider