import * as React from "react"
import { Outlet, useLocation, useOutletContext } from "react-router-dom"
import { Box, Text, Flex, useStyleConfig } from "@chakra-ui/react"

import { AuthPageTitle } from "../components/AuthPageTitle"

import { Link } from '../../components/base/Link'
import { useConstants } from "../../contexts/ConstantsProvider"
import { useUI } from "../../contexts/UIProvider"
import { Card } from "../../components/base/Card"

export const SignupPage = ({ children }) => {

    const constants = useConstants()
    const context = useOutletContext()
    const { variant } = useUI()
    // console.log("variant", variant)
    const strings = constants.strings.auth.signupPage

    let location = useLocation()
    let from = location.state?.from?.pathname || "/"

    const styles = useStyleConfig("SignupPage", { variant })

    return (
        <Box className={"signup-page"} __css={styles} >
            <Card mb={6}  maxW={"lg"}>
                { strings.title && <AuthPageTitle title={ strings.title } color={"primary.500"}/> }
                <Flex justify={"space-around"} >
                    <Box>
                        <Text as={"span"} mb="8" align="center" maxW="md" fontWeight="medium" color={"gray.700"}>
                            {strings.isMember}
                        </Text>
                        <Link
                            size={"sm"}
                            color={"primary.500"}
                            to={constants.auth.loginRoute}
                            state={{ from: { pathname: from } }}
                        >{" "}{strings.loginLink}</Link>
                    </Box>
                </Flex>
            </Card>


            { children || <Outlet context={{ ...context }}/>}
            {/*<Card>*/}
            {/*<SignupForm handleSubmit={handleSubmit} signupError={signupError}/>*/}
            {/*<DividerWithText mt="6">or continue with</DividerWithText>*/}
            {/*<SocialLogin />*/}
            {/*</Card>*/}
        </Box>
    )
}

// const SocialLogin = () => (<>
//     <SimpleGrid mt="6" columns={3} spacing="3">
//     <Button color="currentColor" variant="outline">
//         <VisuallyHidden>Login with Facebook</VisuallyHidden>
//         <FaFacebook/>
//     </Button>
//     <Button color="currentColor" variant="outline">
//         <VisuallyHidden>Login with Google</VisuallyHidden>
//         <FaGoogle/>
//     </Button>
//     <Button color="currentColor" variant="outline">
//         <VisuallyHidden>Login with Github</VisuallyHidden>
//         <FaGithub/>
//     </Button>
// </SimpleGrid>
// // </>)

export default SignupPage
