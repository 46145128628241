import * as yup from "yup"
import LocalizedStrings from "react-localization"

const strings = new LocalizedStrings({
    en: {
        required: "Required",
        passwordsDontMatch: "Passwords do not match"
    },
    ko: {
        required: "필수",
        passwordsDontMatch: "위 비밀번호와 일치하지 않습니다."
    }
})

export const confirmPassword = yup
    .string()
    .oneOf([ yup.ref('password'), null ], strings.passwordsDontMatch)
    .required(strings.required)
    .default("")

export default confirmPassword
