import * as yup from "yup"
import LocalizedStrings from "react-localization"
import { isEmpty, orderBy, reduce } from "lodash"


export class Collection {
    constructor(props) {

        this.name = "" // users
        this.title = "" // Users
        this.modelLabel = "" // "User"

        this.path = "" // "/users"
        this.section = "" // Sidebar section on admin pane
        this.showOnVersions = [] // "admin", "superadmin", "members"
        this.showOnlyWithClaims = [] // empty is show on all
        this.sidebarIcon = null

        this.pagination = false
        this.limit = 50

        this.searchKeys = []
        this.parseDates = []
        this.imageFields = []

        this.schema = {}

        this.strings = new LocalizedStrings({
            en: {},
            ko: {}
        })


        this.colOpts = {
            listen: true,
            // orderBy: [ "createdAt", "desc" ],
            parseDates: this.parseDates

        }

        // server side sort
        this.orderBy = [] // [ "createdAt", "desc" ]
        // client side sort
        this.sortBy = [["createdAt",], ["desc",]]

        this.initialData = []
        this.swrOpts = {
            fallbackData: this.initialData
        }

        this.routes = {}

        this.adminRoutes = {}

        this.selects = []

        Object.assign(this, props)


    }

    onInit() {
        this.parseDates = reduce(yup.object().shape(this.schema).describe().fields, (obj, v, k) => {
            if (v.type === "date") obj.push(k)
            return obj
        }, [])
    }

    getColOpts() {
        let opts = {
            ...this.colOpts,
            parseDates: this.parseDates
        }

        if (!isEmpty(this.orderBy)) opts.orderBy = this.orderBy
        if (this.pagination) opts.limit = this.limit

        return opts
    }

    addSelect(collection) {
        if (!this.selects.find(select => select.name === collection.name)) {
            this.selects.push(collection)
        }
    }

    sort(models) {
        if (!isEmpty(this.sortBy)) return orderBy(models, ...this.sortBy)
        return models
    }

    getRoutes() {
        return this.routes
    }

    getAdminRoutes() {
        return this.adminRoutes
    }

    getSchema() {
        return yup.object().shape(this.schema)
    }

    getImagePreviews() {
        return this.imageFields.reduce((o, k) => {
            o[k] = { dataUrl: "" }
            return o
        }, {})
    }

}


export class Model {
    constructor() {
        this.label = "" // "User"

    }

}

export default Collection
