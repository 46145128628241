import * as React from "react"
import { Outlet } from "react-router-dom"
import { Box, useColorModeValue as mode } from "@chakra-ui/react"
import { MembersNavbar } from "../site/navbar/MembersNavbar"
import { useAuth } from "../auth/contexts/AuthProvider"

export const MembersAppLayout = () => {

    const auth = useAuth()

    return (
        <Box
            className={"members-app-layout"}
            bg={mode('gray.50', 'inherit')}
            minH="100vh"
        >
            <MembersNavbar auth={auth}/>
            <Outlet />
        </Box>
    )
}

export default MembersAppLayout
