// import { mode } from "@chakra-ui/theme-tools"

export const BeatLoader = {
    baseStyle: () => ({
        color: "bg.500",
        // background: mode('gray.50', 'inherit')(props),
    }),
    variants: {
        dreammore: {
            color: "var(--chakra-colors-gray-500)",
        },
        dreamupup: {},
        "dreamupup-bg": {}
    }

}