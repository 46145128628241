import * as React from "react"
import LocalizedStrings from "react-localization"
import { SimpleGrid, Box } from "@chakra-ui/react"

// contexts
import { useOutletContext, useNavigate } from "react-router-dom"
// import { useConstants } from "../../contexts/ConstantsProvider"
import { useCollectionClass } from "../../contexts/CollectionClassProvider"
// import { usePaths } from "../../contexts/PathsProvider"

// components
import Card from "../../components/base/Card"
import PageLoading from "../../components/loading/PageLoading"
// import PageTitle from "../../components/pages/PageTitle"
// import PageDescription from "../../components/pages/PageDescription"
import { useUser } from "../../contexts/UserProvider"
import { usePaths } from "../../contexts/PathsProvider"
import { HeadingGroup } from "../../members/userProfile/HeadingGroup"
import { useUI } from "../../contexts/UIProvider"


export const MembersPricingPlansPage = (props) => {

    let { element: Element } = props
    const { variant } = useUI()

    const strings = new LocalizedStrings({
        en: {
            title: "Pricing Plans",
            description: "You are one click away from joining the Dream Family! Pick your plan."
        },
        ko: {
            title: "패키지 선택하기",
            description: "교육 받으실 교육 패키지를 선택해 주세요!"
        }
    })

    // const constants = useConstants()
    const collection = useCollectionClass()
    const { user } = useUser()
    const navigate = useNavigate()
    const paths = usePaths()

    let context = useOutletContext()
    let { data, loading } = context.cols[collection.name]

    if (loading || !user) return <PageLoading />

    // console.log(variant)
    return (
        <Box>
            <Card shadow={0} variant={`${variant}-bg`}>
                <HeadingGroup
                    variant={variant}
                    title={ strings.title }
                    description={ strings.description }
                />
            </Card>
            {/*<Card my={0} pt={4} pb={3}>*/}
            {/*    {strings.title && <PageTitle title={strings.title} color={"secondary.500"}/>}*/}
            {/*    {strings.description && <PageDescription description={strings.description} color={"gray.800"}/>}*/}
            {/*</Card>*/}

            <SimpleGrid
                alignItems="flex-start"
                mt={{ base: '4', md: '12' }}
                columns={{ base: 1, md: Math.min(data.length, 3) }}
                spacing={{ base: '10', lg: '8' }}
            >
                {collection.sort(data).map(item => {
                    return <Element
                        item={item}
                        key={item.id}
                        // to={}
                        onClick={() => {
                            // console.log(`${paths.rootPath}/${item.id}/checkout`)
                            navigate(`${paths.rootPath}/${item.id}/checkout`)
                        }
                    }/>
                })}
            </SimpleGrid>
        </Box>
    )
}

export default MembersPricingPlansPage
