import * as React from "react"
import { Link as NavLink } from "react-router-dom"
import { Alert, AlertIcon, Button, Flex } from "@chakra-ui/react"
import Card from "../../components/base/Card"

export const AuthErrorCard = ({ errorMessage, buttonLabel, loginRoute }) => (
    <Card maxW={"xl"}>
        <Alert status={"error"} mb={6}>
            <AlertIcon/>{ errorMessage }
        </Alert>
        <Flex w={"full"}>
            <Button as={NavLink} to={loginRoute} w={"full"}>{buttonLabel}</Button>
        </Flex>
    </Card>
)

export default AuthErrorCard
