import { CheckIcon } from '@chakra-ui/icons'
import { Box, Circle, Collapse, Heading, HStack, Icon, useColorModeValue } from '@chakra-ui/react'
import { transparentize } from "@chakra-ui/theme-tools"
import * as React from 'react'
import { useStep } from './useStep'
import { useUI } from "../../contexts/UIProvider"

export const Step = (props) => {
    const ui = useUI()
    const { title, children, ...boxProps } = props
    const { isActive, isCompleted, step } = useStep()
    const accentColor = useColorModeValue(...ui.accentColor)
    const mutedColor = useColorModeValue(...ui.mutedColor)
    const activeColor = useColorModeValue(...ui.activeColor)
    const stepButtonColor = "white"

    return (
        <Box {...boxProps}>
            <HStack spacing="4" className={"step-stack"} color={activeColor}>
                <Circle
                    size="8"
                    fontWeight="bold"
                    // color={isActive ? activeColor : isCompleted ? accentColor : mutedColor}
                    color={isActive ? stepButtonColor : isCompleted ? stepButtonColor : stepButtonColor}
                    bg={isActive ? accentColor : transparentize(accentColor, .5)}
                    borderColor={isCompleted ? accentColor : 'inherit'}
                    borderWidth={isActive ? '0px' : '1px'}
                >
                    {isCompleted ? <Icon as={CheckIcon} /> : step}
                </Circle>
                <Heading
                    fontSize="lg"
                    fontWeight="semibold"
                    color={isActive || isCompleted ? 'inherit' : mutedColor}
                >
                    {title}
                </Heading>
            </HStack>
            <Collapse in={isActive}>{children}</Collapse>
        </Box>
    )
}