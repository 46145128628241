import { CloseButton, Flex, useStyleConfig } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { useUI } from "../../contexts/UIProvider"

export const MembersMobileNavContent = (props) => {
    const { isOpen, onClose, children } = props
    const ui = useUI()
    const { variant } = ui
    const styles = useStyleConfig("MobileNavContent", { variant })

    // console.log("nav", styles)
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    transition={{
                        duration: 0.1,
                    }}
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    exit={{
                        opacity: 0,
                    }}
                >
                    <Flex
                        direction="column"
                        w="100%"
                        h="100vh"
                        overflow="auto"
                        pos="absolute"
                        top={0}
                        left={0}
                        zIndex={20}
                        px={4}
                        py={4}
                        __css={styles}
                    >
                        <Flex direction="column" h={"full"}>{children}</Flex>
                        <CloseButton pos="absolute" top={4} right={4} onClick={onClose}/>
                    </Flex>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default MembersMobileNavContent
