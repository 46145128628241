import timestamp from "./timestamps"
import date from "./dates"
import { format } from "date-fns"

export const formatInnopayDate = date => format(date, "yyyyMMddHHmmss")

const timestampTransform = (value, originalValue, context) => {
    if (context.isType(value)) return value
    return value?.toDate ? value.toDate() : new Date()
}


export const dates = {
    date,
    timestamp,
    timestampTransform,
    formatInnopayDate
}

export default dates
