import * as yup from "yup"
import LocalizedStrings from "react-localization"

const strings = new LocalizedStrings({
    en: {
        required: "Required",
        tooShort: "Password must be at least {min} characters",
    },
    ko: {
        required: "필수",
        tooShort: "비밀번호는 영문,숫자포함 {min}글자 이상입니다. ",
    }
})

const minPasswordLength = 6

export const password = yup.string()
        .trim()
        .required(strings.required)
        .min(minPasswordLength, strings.formatString(strings.tooShort, { min: minPasswordLength }))
        .default("")

export default password
