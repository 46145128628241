import { Buffer } from "buffer"
import md5 from "js-md5"

export function encode64 (txt) {
    return Buffer.from(txt).toString("base64")
}

export const encoding = {
    encode64,
    md5
}

export default encoding
