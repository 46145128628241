import * as React from "react"
import { Navigate, Outlet } from "react-router-dom"

import AuthLayout from "../auth/layouts/AuthLayout"
import MembersAppLayout from "../members/MembersAppLayout"
import MembersPageLayout from "../members/MembersPageLayout"

import LoginPage from "../auth/pages/LoginPage"
import AuthActionPage from "../auth/pages/AuthActionPage"
import ResetPasswordPage from "../auth/pages/ResetPasswordPage"
import ForgotPasswordPage from "../auth/pages/ForgotPasswordPage"
import SignupPage from "../auth/pages/SignupPage"

import SignupForm from "../classes/MembersSignup/SignupForm"

import AuthLoading from "../auth/components/AuthLoading"
import RequireAuth from "../auth/components/RequireAuth"

import CollectionClassProvider from "../contexts/CollectionClassProvider"
import SiteProvider from "../contexts/SiteProvider"
import PathsProvider from "../contexts/PathsProvider"

import MembersPricingPlansPage from "../classes/PricingPlans/MembersPricingPlansPage"
// import PricingPlanCard from "../components/pricingPlan/PricingPlanCard"
import GoToEditUserPage from "../pages/GoToEditUserPage"
import FormPage from "../pages/FormPage"
import DocLayer from "../layers/DocLayer"
import PlansLayer from "../classes/PricingPlans/PlansLayer"
// import AccountSettingsPage from "../classes/AccountSettings/AccountSettingsPage"
import { MembersUserProfilePage } from "../classes/Users/MembersUserProfilePage"

import TransactionResultView from "../classes/Transactions/TransactionResultView"
import PricingPlanCheckoutForm from "../classes/CheckoutMembers/PricingPlanCheckoutForm"
import PricingPlanCard from "../members/pricingPlan/PricingPlanCard"
import MembersSignupConfirmationPage from "../classes/Users/MembersSignupConfirmationPage"
// import ViewPage from "../pages/ViewPage"
import { MembersUserSignatureFormPage } from "../classes/Users/MembersUserSignatureFormPage"

// import { HomePage } from "../site/pages/HomePage"

export const getMemberRoutes = (collections) => ([
    {
        name: "protected",
        element: <AuthLoading>
            <RequireAuth>
                <SiteProvider>
                    <MembersAppLayout/>
                </SiteProvider>
            </RequireAuth>
        </AuthLoading>,
        children: [
            {
                path: "/plans",
                element: <CollectionClassProvider value={collections.plans}>
                    <PathsProvider>
                        <MembersPageLayout>
                            <PlansLayer/>
                        </MembersPageLayout>
                    </PathsProvider>
                </CollectionClassProvider>,
                children: [
                    {
                        index: true,
                        element: <MembersPricingPlansPage element={PricingPlanCard}/>
                    },
                    {
                        path: ":id",
                        element: <DocLayer/>,
                        children: [
                            { path: "checkout", element: <PricingPlanCheckoutForm/> }
                        ]
                    }
                ]
            },
            {
                path: "/users",
                element: <CollectionClassProvider value={collections.users}>
                    <PathsProvider>
                        <MembersPageLayout>
                            <Outlet/>
                        </MembersPageLayout>
                    </PathsProvider>
                </CollectionClassProvider>,
                children: [
                    { index: true, element: <GoToEditUserPage/> },
                    {
                        path: ":id",
                        element: <DocLayer/>,
                        children: [
                            {
                                path: "edit",
                                element: <FormPage/>,
                                children: [
                                    { index: true, element: <MembersUserProfilePage/> }
                                ]
                            },
                            {
                                path: "signature",
                                element: <FormPage/>,
                                children: [
                                    { index: true, element: <MembersUserSignatureFormPage/> }
                                ]
                            },
                            {
                                path: "view",
                                element: <FormPage />,
                                children: [
                                    { index: true, element: <MembersSignupConfirmationPage />}
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: "/transactions",
                element: <CollectionClassProvider value={collections.transactions}>
                    <PathsProvider>
                        <MembersPageLayout>
                            <Outlet/>
                        </MembersPageLayout>
                    </PathsProvider>
                </CollectionClassProvider>,
                children: [
                    { index: true, element: <Navigate to={"/users"}/> },
                    {
                        path: ":id",
                        element: <DocLayer/>,
                        children: [
                            { path: "view", element: <TransactionResultView/> }
                        ]
                    }

                ]
            }
        ]
    },
    {
        path: "/auth",
        element: <AuthLayout py={"8"}/>,
        children: [
            {
                path: "login",
                element: <Outlet/>,
                children: [
                    { index: true, element: <LoginPage/> },
                    { path: "action", element: <AuthActionPage/> },
                    { path: "reset", element: <ResetPasswordPage/> },
                    { path: "forgot", element: <ForgotPasswordPage/> }
                ]
            },
            {
                path: "signup",
                element: <SignupPage/>,
                children: [
                    {
                        index: true, element: <SignupForm/>
                    }
                ]
            }
        ]
    },

    // {
    //     path: "/",
    //     element: <MembersAppLayout/>,
    //     children: [
    //         { index: true, element: <HomePage/> }
    //     ]
    // },

    // default route
    {
        path: "*",
        element: <Navigate to={"/users"}/>
    }
])

export default getMemberRoutes
