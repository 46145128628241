import * as yup from "yup"
import LocalizedStrings from "react-localization"

const strings = new LocalizedStrings({
    en: {
        startsWith: "Must start with {0}",
        isLength: "Too long or short"
    },
    ko: {
        startsWith:  "{0}으로 시작하는 번호를 입력해주세요.",
        isLength: "휴대폰 번호를 확인해 주세요."
    }
})


const prefixes = ["010", ]

export const koreanMobile = yup.string()
    .default("")
    .required("")
    .test("is-right-length", strings.isLength, value => value.replace(/[- ]/g, "").length === 11)
    .test("starts-with-prefix", strings.formatString(strings.startsWith, prefixes.join(" or ")),
            value => prefixes.some(prefix => value.startsWith(prefix)))

export default koreanMobile
