import {
    Avatar,
    Box,
    Button,
    Icon,
    // FormControl,
    // FormLabel,
    HStack,
    // Select,
    Stack,
    StackDivider,
    // Switch,
    Text, Image,
} from '@chakra-ui/react'
import * as React from 'react'
import { Card } from '../../components/base/Card'
import CardLoading from "../../components/loading/CardLoading"
import { FieldGroup } from '../../members/userProfile/FieldGroup'
import { HeadingGroup } from '../../members/userProfile/HeadingGroup'
import { useUser } from "../../contexts/UserProvider"
import { useUI } from "../../contexts/UIProvider"
import { useConstants } from "../../contexts/ConstantsProvider"
import { HiBadgeCheck } from 'react-icons/hi'
import { MembersBasicInfoForm } from "./MembersBasicInfoForm"
import { format } from "date-fns"
import { PiSignature } from "react-icons/pi"
import { useNavigate } from "react-router-dom"

export const MembersAccountSettings = (props) => {

    const constants = useConstants()
    const { user } = useUser()
    const { variant } = useUI()
    const navigate = useNavigate()

    const [ isEditing, setIsEditing ] = React.useState({
        "basicInfo": false,
        "password": false,
        "signature": false
    })

    const strings = constants.strings.members.accountSettings

    if (!user) return <CardLoading size={"lg"} w={"full"}/>

    return (
        <Stack as="section" spacing="4" {...props}>
            <Card shadow={0} variant={`${variant}-bg`}>
                <HeadingGroup
                    variant={variant}
                    title={strings.basicSettingsTitle}
                    description={strings.basicSettingsDescription}
                />
                <Button
                    my={2}
                    py={2}
                    ml={1}
                    variant={"ghost"}
                    bg={"white"}
                    px={2}
                    onClick={() => navigate(`/users/${user.id}/view`)}
                >{ constants.strings.members.memberConfirmation.title }</Button>
            </Card>

            <Card>
                <Stack divider={<StackDivider/>} spacing="6">
                    <FieldGroup title="Name &amp; Avatar" description="Change your name and profile picture">
                        {!isEditing.basicInfo && <><HStack spacing="4">
                            <Avatar
                                bg={"gray.200"}
                                src={user.photoUrl?.src}
                                name={user.name}
                            />
                            <Box>
                                <HStack>
                                    {user.market_name && <Text>{user.market_name}</Text>}
                                    <Text>{user.name}</Text>
                                    {user.subscription_date &&
                                        <Icon as={HiBadgeCheck} color="green.300" verticalAlign="text-bottom"/>}
                                </HStack>
                                <Text color="gray.500" fontSize="sm">
                                    {user.createdAt && format(user.createdAt, "yyyy년 MM월 dd일")}
                                </Text>
                            </Box>
                        </HStack>
                            <HStack mt="5" justify={"flex-end"}>
                                <Button
                                    size="md"
                                    onClick={() => setIsEditing(prev => ({ ...prev, basicInfo: true }))}
                                    fontWeight="normal" bg={"gray.100"} color={"gray.900"} _hover={{
                                    bg: "gray.400", color: "white"
                                }}>
                                    Edit
                                </Button>

                            </HStack></>}
                        {isEditing.basicInfo && <MembersBasicInfoForm
                            onClose={() => setIsEditing(prev => ({ ...prev, basicInfo: false }))}
                        />}
                    </FieldGroup>

                    <FieldGroup title={<HStack as={"span"}><Text as={"span"}>Signature</Text><PiSignature size={24}/></HStack>}
                                description={null}>
                        {!isEditing.signature && <HStack mt="0" justify={"space-between"} w={"full"} align={"flex-end"}>
                            {user.signature ? <Image src={user.signature} alt={"signature"} w={24} h={"auto"}/> : <Box />}
                            <Button
                                onClick={() => {
                                    // console.log(`/users/${user.id}/signature`)
                                    navigate(`/users/${user.id}/signature`)
                                }}
                                size="sm" fontWeight="normal" bg={"gray.100"} color={"gray.900"}>
                                {strings.changeSignature}
                            </Button>
                        </HStack>}
                    </FieldGroup>

                    {/*<FieldGroup title="Login details" description={user.email}>*/}
                    {/*    <Text fontSize="sm" color={"brand.500"}>{user.email}</Text>*/}
                    {/*    <HStack mt="5" justify={"flex-end"}>*/}
                    {/*        <Button size="sm" fontWeight="normal" bg={"gray.100"} color={"gray.900"}>*/}
                    {/*            {strings.changePassword}*/}
                    {/*        </Button>*/}
                    {/*    </HStack>*/}
                    {/*</FieldGroup>*/}

                </Stack>
            </Card>
        </Stack>
    )
}
