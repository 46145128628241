import { Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { numberWithCommas } from "../../utils"

export const PricingPlanItem = ({ plan }) => {

  return (
    <Flex justify="space-between" key={plan.id}>
      <Stack direction="row" spacing="5">
        {/*<AspectRatio ratio={1} width="92px">*/}
        {/*  <Image src={product.imageUrl} alt={plan.name} borderRadius="md" />*/}
        {/*</AspectRatio>*/}
        <Stack spacing="3">
          <Stack spacing="1">
            <Text fontWeight="semibold">{plan.name}</Text>
            <Text color={useColorModeValue('gray.600', 'gray.300')}>{ plan.description }</Text>
          </Stack>
        </Stack>
      </Stack>
      <Text fontWeight="medium">{ numberWithCommas(plan.price) }</Text>
    </Flex>
  )
}

export default PricingPlanItem
