import { mode } from "@chakra-ui/theme-tools"

export const GalleryUpload = {
    parts: ["bookmark"],
    baseStyle: props => ({
        navbar: {
            bg: mode("white", "gray.800")(props)
        }
    }),
    variants: {
        dreammore: {
            navbar: {
                // bg: "brown.300",
                bg: "bg2.50",
                shadow: "lg",
                shadowColor: "white",
                color: "white"
            },
            hamburger: {
                color: "white"
            }

        },
        dreamupup: {
            navbar: {
                bg: "primary.100",
                shadow: "lg",
                color: "white"
            },
            hamburger: {
                color: "primary.500"
            }

        }
    }
}